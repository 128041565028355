<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 90%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=90%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsgToUse + 'px', 'line-height': this.fontSizePopUpMsgLineHeightToUse + 'px', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }} <a :href="'https://stripe.com/' + this.stripeCountryCodeToUse  + 'connect/pricing'" target="_blank">here</a> (see 'custom accounts')<br><br>{{ this.popUpMsgBody2 }}

              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
    <!-- showTipFeesInfoRecipient -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 
            <table width=100% border=0 style="pmargin: auto; padding-left: 0vh; padding-right: 0vh; ">
                <tr>
                    <td>
                        <table width="100%" border=0 style="height: 6vh">
                            <tr>
                                <td width="30%">
                                    <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                                </td>
                                <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Fees breakdown</span>
                                </td>
                                <td width="30%">
                                    &nbsp;
                                </td>

                            </tr>
                        </table>
                        
                    </td>
                </tr>
                <tr>
                    <td height=2vh >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; padding-left: 2vh; padding-right: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">thankU provides a full breakdown of all fees deducted from your tip. <span v-if="this.payment.tipperPaidFees === false">Our own fee is the 'thankU fee' below, and we pass through transaction charges from Stripe, our payment processor, these are the 'Stripe flat fee' and 'Stripe processing fee'. </span><span v-if="this.payment.tipperPaidFees === false && this.payment.stripeMonthlyActiveUserFee === undefined">For more info, see the 'transaction fees' link below.</span><span v-if="this.payment.tipperPaidFees === true">On this transaction, your tipper paid your transaction charges. </span><span v-if="this.payment.stripeMonthlyActiveUserFee !== undefined">The 'Stripe monthly active user' and 'stripe monthly payout flat fee' are once per month platform fees, see the 'platform fees' link below.</span></div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="userinfoframenowidth" style="display: block; height: auto; padding-top: 2vh; padding-bottom: 2vh; padding-left: 2vh; padding-right: 2vh" border=0>
                            <tr>
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.tipperName }} tipped you </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.amountChosen }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.payment.tipperPaidFees === false">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">thankU fee @ {{ this.payment.transactionFeeThankUPercentReceipt }}% </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.thankUFeeTransferred }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.payment.tipperPaidFees === false">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Stripe flat fee </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.transactionFeeOnTopAmount }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.payment.tipperPaidFees === false">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Stripe processing fee @ {{ this.payment.transactionFeePlatformPercentReceipt }}%</div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.transactionFeePlatformAmountReceipt }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="this.payment.stripeMonthlyActiveUserFee">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Stripe monthly active user</div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.stripeMonthlyActiveUserFee }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="this.payment.stripePayoutFlatFee">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">stripe monthly payout flat fee</div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.stripePayoutFlatFee }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="this.payment.stripePayoutVolumeFee">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Stripe payout volume fee</div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.stripePayoutVolumeFee }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table">
                                    <div class="lt-blue cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">You received
                                        </div>
                                    <div class="cell" style="color: #FFFFFF" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.amount }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td height=2vh style="padding-top: 0vh" >

                                    <div class="divl" style="color: #718096; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">{{ this.payment.tipDate }}</div>
                                        
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height=2vh style="padding-top: 1vh" >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divc" style="color: #718096; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Transaction ID: {{ this.payment.transactionId}} </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="userinfoframe" style="" >
                    <td style="padding-top: 0vh; " >
                         
                         <div v-if="this.payment.stripeMonthlyActiveUserFee !== undefined" class="divc" style="color: #718096; padding-left: 2vh; padding-right: 2vh;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">

                            Details of Stripe's published fees: <button class="text-only-button cancel" @click="this.doShowTransactionFees()" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">transaction fees</button> and <button class="text-only-button cancel" @click="this.doShowPlatformFeesPopUp();" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">platform fees</button>
                            
                            <!-- //and https://stripe.com/" + {{ this.payment.stripeCountryCode }} + "/connect/pricing (custom connected accounts)<br><br>"; -->

                        </div>

                        <div v-if="this.payment.stripeMonthlyActiveUserFee === undefined" class="divc" style="color: #718096; padding-left: 2vh; padding-right: 2vh;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">

                            Details of Stripe's published transaction fees: <button class="text-only-button cancel" @click="this.doShowTransactionFees()" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">tap here</button>.
                            
                            <!-- //and https://stripe.com/" + {{ this.payment.stripeCountryCode }} + "/connect/pricing (custom connected accounts)<br><br>"; -->

                        </div>
                                
                    </td>
                </tr>

            </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');
import shared from '../shared.js';

export default {
    name: 'TipFeesInfoScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            payment: {},
            vhMult: 1,
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            popUpMsgBody2: "",
            fontSizeTitle: 2.7, // vh
            fontSizeTitleLineHeight: 5, //vh
            fontSizePopUpMsg: 2.3, // vh
            fontSizePopUpMsgToUseToUse: "",
            fontSizePopUpMsgLineHeight: 3.4,// vh
            fontSizePopUpMsgLineHeightToUse: "",
            stripeCountryCodeToUse: "",
            recipientMonthlyFees: {},
            monthlyFeeThreshold: "",
            fixedStripePayoutFlatFee: "",
            stripePayoutFlatFeeDaily: "",
            

        }
    },
    props:{
        userData: shared.userDataStruct(),
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
            this.fontSizePopUpMsgToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizePopUpMsg, 16);
            this.fontSizePopUpMsgLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizePopUpMsgLineHeight, 20);
        },
        goBack(){
            this.userData.nextScreen = "showTipInfoRecipient";
            this.userData.selectedPaymentObject = this.payment;
            this.$emit("next-action", this.userData);
        }, 
        doPopUpOk(){
            this.showPopUpOk = false;
        },   
        doShowTransactionFees(){
            window.open("https://stripe.com/" + this.stripeCountryCodeToUse + "pricing");
        },
        doShowPlatformFeesPopUp(){
            // console.log("doShowPlatformFeesPopUp");
            // <a :href="'https://stripe.com/' + this.payment.stripeCountryCode  + '/connect/pricing'">platform fees</a> (custom connected accounts)
            this.popUpMsgTitle = "Stripe platform fees";
            
            this.popUpMsgBody = "In addition to per transaction fees, thankU's payment processor Stripe charges a fee per month if your account is active, and fees every time a payment is made to you - thankU passes on these fees. Stripe's platform fees are detailed "

            this.popUpMsgBody2 = "thankU deducts the Stripe 'active user per month' fee on the first tip of the month which is " + this.payment.recipientCurrencySymbol + this.monthlyFeeThreshold + " or greater (otherwise thankU pays it for you), however to avoid confusion thankU passes on the Stripe 'per payout' fee as a once per month flat fee of " + this.payment.recipientCurrencySymbol + this.fixedStripePayoutFlatFee + " instead of deducting " + this.payment.recipientCurrencySymbol + this.stripePayoutFlatFeeDaily + " on every day a payment is made to you, which is what Stripe actually charges.";
            
            this.showPopUpOk = true;
        },
        // getRecipientMonthlyFees(recipientMonthlyFees){

        //     try {
                
        //         let monthlyActiveUserFee;
        //         let stripePayoutFlatFee;
        //         let monthlyFeeThreshold;
        //         let stripePayoutFlatFeeDaily;

        //         let recipientFeesArray = recipientMonthlyFees;

        //         // console.log("recipientCurrencyCode: " + recipientCurrencyCode);

        //         console.log("recipientFeesArray: " + JSON.stringify(recipientFeesArray, null, 2));

                
        //         monthlyActiveUserFee = recipientFeesArray.monthlyActiveUserFee;
        //         stripePayoutFlatFee = recipientFeesArray.stripePayoutFlatFee;
        //         monthlyFeeThreshold = recipientFeesArray.monthlyFeeThreshold;
        //         stripePayoutFlatFeeDaily = recipientFeesArray.stripePayoutFlatFeeDaily;

    
        //         // for (let item of recipientFeesArray) {
        //         //     if (item[0] === recipientCurrencyCode){
        //         //         monthlyActiveUserFee = +item[1];
        //         //     }
        //         //     if (item[0] === (recipientCurrencyCode + "Payout")){
        //         //         stripePayoutFlatFee = +item[1];
        //         //     }

        //         //     if (item[0] === (recipientCurrencyCode + "Threshold")){
        //         //         // if (recipientPoolId !== undefined && (recipientPoolId === "309" || recipientPoolId === "334" || recipientPoolId === "335" ||  // the Edward James salons
        //         //         //     recipientPoolId === "215" // Hair Gallery 
        //         //         //     )) {
        //         //         //     monthlyFeeThreshold = "10"; // not £5
        //         //         //     // console.log("Edward James threshold is £10 in TipFeesInfoRecipient");
        //         //         // } else {
        //         //             monthlyFeeThreshold = +item[1];
        //         //         // }
                        
        //         //     }

        //         //     if (item[0] === (recipientCurrencyCode + "PayoutDaily")){
        //         //         stripePayoutFlatFeeDaily = +item[1];
        //         //     }

        //         // }
        //         return {
        //             monthlyActiveUserFee: monthlyActiveUserFee,
        //             stripePayoutFlatFee: stripePayoutFlatFee,
        //             monthlyFeeThreshold: monthlyFeeThreshold,
        //             stripePayoutFlatFeeDaily: stripePayoutFlatFeeDaily,
        //         }
        //     } catch (e) {
        //         // console.log("ERROR in getRecipientMonthlyFees: " + e.message);
        //         throw "ERROR in getRecipientMonthlyFees: " + e.message;
        //     }
        // },

    },
    created(){
        this.payment = this.userData.selectedPaymentObject;

        // console.log("TipFeesInfoRecipient this.payment: " + JSON.stringify(this.payment, null, 2));
        this.stripeCountryCodeToUse = this.payment.stripeCountryCode !== "us" ? this.payment.stripeCountryCode + "/": "";
        this.recipientMonthlyFees = this.payment.recipientMonthlyFees;
        // let recipientMontlyFeesData = this.getRecipientMonthlyFees(this.payment.recipientMonthlyFees);
        // console.log("recipientMontlyFeesData: " + JSON.stringify(recipientMontlyFeesData, null, 2));
        // console.log("tipperPaidFees: " + this.payment.tipperPaidFees);

        this.monthlyFeeThreshold = this.payment.recipientMonthlyFees.monthlyFeeThreshold;
        this.fixedStripePayoutFlatFee = this.payment.recipientMonthlyFees.stripePayoutFlatFee;
        this.stripePayoutFlatFeeDaily = this.payment.recipientMonthlyFees.stripePayoutFlatFeeDaily;


    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Tip Fees info " + new Date().toISOString(), this.globalPushForwardInterval);
    }
}
</script>

<style scoped>
.table {
  height:4vh;
  display: grid;
  /* outline:0.01em solid black; */
  grid-template-columns: auto auto auto;
}

.cell {
  text-overflow: clip;
  /* color: #bdc2ca; */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: left;
  align-items: center;
  /* outline:0.01em solid black; */
  
}

</style>