<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 70%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div style="padding-top: 2vh"><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
    </transition>
    <!-- showDisplaynameEdit --><transition name="fade">
    <div v-show="showDisplaynameEdit===true" id="topdiv" class="modal-overlay" style="width: 100%">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; height:90vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="60%">
                                Update display name
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
           
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh align=center>
                    <table width="70%" border=0 style="height: 2vh">
                        <tr>
                            <td width="70%">
                                <div class="divc" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">What would you like your display name to be?</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td>
                    <div style="height: 6vh; display: flex;">
                    <input type="textbox" class="standard-textbox input" ref="displayname-edit" placeholder="Display name">
                    </div>
                </td>
            </tr>
          
            <tr>
                <td style="height: 12vh">
                    <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doUpdateDBDisplayName();">Update</button></div>
                </td>
            </tr>
            <tr>
                <td >
                    <div style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.displaynameMsg }}</div>
                </td>
            </tr>
        
 
          
            
            <tr><td height=70vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>  
        </table> 
        
      </div>
    </div>
    </transition>
    <!-- showEmailEdit --><transition name="fade">
    <div v-show="showEmailEdit===true" id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; height:90vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="60%">
                                Update email
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
           
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh align=center>
                    <table width="70%" border=0 style="height: 2vh">
                        <tr>
                            <td width="70%">
                                <div class="divc" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Which email address would you like to use?</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
                <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input type="textbox" class="standard-textbox input" ref="email-edit" placeholder="Email">
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 12vh">
              <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doUpdateDBEmail();">Update</button></div>
            </td>
          </tr>
          <tr>
            <td >
                <div style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.emailMsg }}</div>
            </td>
          </tr>
            <tr><td height=70vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>  
        </table> 
        
      </div>
    </div>
    </transition>
    <!-- showPasswordEdit --><transition name="fade">
    <div v-show="showPasswordEdit===true" id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; height:90vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="60%">
                                Change password
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
           
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh align=center>
                    <table width="70%" border=0 style="height: 2vh">
                        <tr>
                            <td width="70%">
                                <div class="divc" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">What would you like your new password to be?</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
              <td>
                <div data-private="ipsum" style="height: 6vh; display: flex;">
                    <input type="textbox" class="standard-textbox input" ref="pw-edit" placeholder="..more than 4 letters long..">
                </div>
              </td>
          </tr>
          <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
          <tr>
                <td height=2vh align=center>
                    <table width="80%" border=0 style="height: 2vh">
                        <tr>
                            <td width="80%">
                                <div class="divc" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Please confirm your new password</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
          </tr>
          <tr>
              <td>
                <div data-private="ipsum" style="height: 6vh; display: flex;">
                    <input type="textbox" class="standard-textbox input" ref="pwconfirm-edit" placeholder="Password confirmation">
                </div>
              </td>
          </tr>
          <tr>
            <td style="height: 12vh">
              <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doUpdateDBPassword();">Change</button></div>
            </td>
          </tr>
          <tr>
            <td >
                <div style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Your password is encrypted through a ‘one-way’ algorithm, meaning we can never view it.</div>
            </td>
          </tr>
            <tr><td height=70vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>  
        </table> 
        
      </div>
    </div>
    </transition>
    <!-- showPhotoEdit -->
    <div v-show="showPhotoEdit===true" id="showPhotoEdit" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

            <table cellspacing="0" cellpadding="0" width=100% border=0 style="border-style: dotted; padding-left: 2.5vh; padding-right: 2.5vh; " :style="{ 'height': 70 * this.vhMult + 'vh'}">
                <tr>
                    <td>
                        <table width="100%" border=0 style="height: 6vh">
                            <tr>
                                <td width="27%">
                                    <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                                </td>
                                <td width="46%">
                                    Account photo
                                </td>
                                <td width="27%">
                                
                                </td>

                            </tr>
                        </table>
                        
                    </td>
                </tr>
                <tr style="z-index: 500">
                    <td ref="imageCell" id="imageCell" valign="top" align="left" style="outline:0.0em solid red;" :style="{ 'height': 76 * this.vhMult + 'vh'}">
                        <div style="position:relative; outline:0.0em dashed purple" :style="{ 'height': 76 * this.vhMult + 'vh'}"> 
                            <div class="divc" style="padding-left: 3vh; padding-right: 3vh; padding-top: 1vh;  margin: auto; z-index: 999; position:absolute;  outline:0.0em dashed pink" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.8 + 'px' }">Your photo will be shown to the other party if you provide one - most people like the personal connection and for recipients this can help your clients find you if you are in a list of other recipients to choose from</div>
                            <!-- Used in place of an image tag -->
                            <canvas style="position:relative;overflow:scroll;outline:0.0em solid pink; "
                                id="canvas"
                                ref="canvas"
                                v-bind:x="inputX"
                                v-bind:y="inputY"
                                v-bind:height="height"
                                v-bind:width="width"
                                v-bind:class="{ 'is-draggable': hoverIsDraggable }">
                            </canvas>
                            <canvas @click="this.doPhotoActionButton()" style="position:absolute; outline:0.0em solid green; pointer-events: none; "
                                id="imgOutput"
                                ref="imgOutput"
                                v-bind:x="outputX"
                                v-bind:y="outputY"
                                v-bind:height="outputHeight"
                                v-bind:width="outputWidth">
                            </canvas>
                            <canvas style="z-index: 400; position:absolute; left:0; top:0; margin: auto; outline:0.0em solid yellow; pointer-events: none; "
                                id="imgMask"
                                ref="imgMask"
                               >
                            </canvas>
                            
                            <div ref="providePhotoButtonDiv" class="" style="width: 100%; z-index: 999; position:absolute; outline:0.0em solid blue; ">
                                <!-- Hidden input we use to access file contents -->
                                <form ref="form" style="outline:0.0em solid white;">
                                    <input @change="previewThumbnail" ref="fileInput" type="file" style="color: rgba(0, 0, 0, 0);display: none;" />
                                </form>
                                <div v-on:click="triggerInput"></div>
                                <div class="image-input__control-buttons divc">
                                    
                                    <button ref="providePhotoButton" class="widebtn txtc" style="width: 60%; height: 6vh" @click="this.doPhotoActionButton()" type="button" >{{ this.providePhotoButtonText }}</button>
                                </div>
                            </div>
                
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <!-- showAccountScreen -->
    <div v-show="showRecipientEditScreen===true" id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table ref="mainTable" cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="27%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="46%">
                            <div class=divc>
                                Edit account
                            </div>
                            </td>
                            <td width="27%">
                            <div class=divr>
                            

                            </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>

            <div style="height: 15vh">
            <table  @click="doShowPhotoEdit()" style="height:85%; cursor: pointer" border=0 width=100%>
            <thead>
            <tr>
                <td align=left width=25% style="height: 8vh; font-size: 0; text-align: center"><img :src="this.userImageDataURL" style="height: 12vh; border-radius:50%"></td>
            </tr>
            <tr>
                <td >
                <div class="divc" style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">change</div>
                </td>
            </tr>
            </thead>
            </table>
        </div>
            
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh >
                    <table width="100%" border=0 style="height: 2vh">
                        <tr>
                            <td width="100%">
                                <div class="divl" style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">DISPLAY NAME</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr @click="doEditDisplayname();">
                <td>
                    <table align=left style="height:7vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                        
                        <tr>

                            <td align=left width=90% class="lt-blue divl" style="padding-left: 2vh; height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                {{ this.user.displayname }}
                            </td>
                            <td align=right width=10% >
                                <img class="tulogo" style="height: 1.8vh;" alt="back" src="../assets/chevronright.png">
                            </td>
                            
                        </tr>
                    </table>      
                </td>
            </tr>

            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh >
                    <table width="100%" border=0 style="height: 2vh">
                        <tr>
                            <td width="100%">
                                <div class="divl" style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">EMAIL</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>

            <tr @click="doEditEmail();">
                <td>
                    <table align=left style="height:7vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                        
                        <tr>

                            <td align=left width=90% class="lt-blue divl" style="padding-left: 2vh; height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                {{ this.user.email }}
                            </td>
                            <td align=right width=10% >
                                <img class="tulogo" style="height: 1.8vh;" alt="back" src="../assets/chevronright.png">
                            </td>
                            
                        </tr>
                    </table>      
                </td>
            </tr>
            
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh >
                    <table width="100%" border=0 style="height: 2vh">
                        <tr>
                            <td width="100%">
                                <div class="divl" style="color: #718096; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">PASSWORD</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>

            <tr @click="doChangePassword();">
                <td>
                    <table align=left style="height:7vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                        
                        <tr>

                            <td align=left width=90% class="lt-blue divl" style="padding-left: 2vh; height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                Change password
                            </td>
                            <td align=right width=10% >
                                <img class="tulogo" style="height: 1.8vh;" alt="back" src="../assets/chevronright.png">
                            </td>
                            
                        </tr>
                    </table>      
                </td>
            </tr>
            
            <tr><td height=70vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>  
        </table> 
        
        </div>
    </div>
    
</template>

<script>

const Parse = require('parse/node');

import shared from '../shared.js';

export default {
    name: 'AccountEditScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    data () {
        return {
            fontSizeNormal: 3.0, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.6,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 3.0, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 3.0,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            user: {},
            userImageDataURL: "",
            showPopUpTwoOptions: false,
            PopUpTwoOptionsTitle: "",
            PopUpTwoOptionsMessage: "",  
            option1ButtonText: "",
            option2ButtonText: "",
            showRecipientEditScreen: true,
            showDisplaynameEdit: false,
            showEmailEdit: false,
            showPasswordEdit: false,
            displaynameMsg: "",
            emailMsg: "",
            pwMsg: "",
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            showPhotoEdit: false,
            dataURL: "",
            goat: "",
            sourceOffsetX: 0,
            sourceOffsetY: 0,
            sourceCameraZoom: 0,
            offsetXOriginal: 0,
            offsetXNew: 0,
            offsetYOriginal: 0,
            offsetYNew: 0,
            sourceImage: undefined,
            // these all relate to newPanZoom
            isImageLoaded: false,
            hoverIsDraggable: false,
            // Image
            ctx: null, canvas: null,
            img: null, 
            // Properties
            scaleSteps: 0,
            imageX: 0, imageY: 0,
            scaledImageWidth: 0, scaledImageHeight: 0,
            imageWidth: 0, imageHeight: 0, imageRight: 0, imageBottom: 0,
            // State
            draggingImage: false,
            // Mouse 
            startX: 0, startY: 0,
            mouseX: 0, mouseY: 0,
            inputX: 0, inputY: 0,
            width: 0, height: 0,
            outputWidth: 0, outputHeight: 0,
            outputX: 0, outputY: 0,
            // end of newPanZoom
            vhMult: 1,
            providePhotoButtonText: "Provide photo",
            actionStatus: "provide",
            willNeedToReload: false,
        }
    },
    computed: {
        containerStyles: function () {
            return {
                width: this.width,
                height: this.height
            };
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        
        calcWidths(){

            const tableWidth = this.$refs.mainTable.offsetWidth;
            const desiredElementWidth = tableWidth * .6; //above 0.6 it starts truncating

            // console.log("tablewidth: " + tableWidth);

            this.width = desiredElementWidth;
            this.height = desiredElementWidth * 1.4; // slightly arbitrary, the original dimensions I had for canvas before adjusting for screen size was W: 250 H: 350
            this.outputWidth = desiredElementWidth;
            this.outputHeight = desiredElementWidth;
            

        },
        async doPhotoActionButton(){
            try {
                if (this.actionStatus === "provide"){
                    this.$refs.fileInput.click();
                } else if (this.actionStatus === "submit") {
                    await this.saveImage();
                    this.popUpMsgTitle = "Great!";
                    this.popUpMsgBody = "your photo has been saved";
                    this.showPopUpOk = true;
                }
            } catch (e) {
                Parse.Cloud.run("sendEmail", {
                    toEmail: "appalert@thanku.app", 
                    subject: "ALERT! save photo failed: " + e.message,
                    body: "save photo failed for user " + this.user.displayname + " " + this.user.userObjectId + " with error " + e.message,
                });
            }
        },
        goBack(){
            if (this.showRecipientEditScreen === false){
                this.showDisplaynameEdit = false;
                this.showEmailEdit = false;
                this.showPasswordEdit = false;
                this.showPhotoEdit = false;
                this.showPopUpOk = false;
                this.showRecipientEditScreen = true;
            } else {
                this.doShowAccountScreen();
            }
        },
        async doShowPhotoEdit(){

            if (this.willNeedToReload === true) {

                // console.log("I am reloading");
                this.willNeedToReload = false;
                
                this.user.nextScreen = "ReloadAccountPhotoScreen";

                await this.$emit("next-action", this.user);

            } else {

                this.showRecipientEditScreen = false;
                this.showPhotoEdit = true;
                // the above will make it visible, the below will resize things after it has rendered
                this.$nextTick(() => { 
                    
                    this.centreRelativeCanvas(this.$refs.imgOutput, this.$refs.imageCell);
                    // this.centreRelativeCanvas(this.$refs.imgMask, this.$refs.imageCell);
                    this.centreRelativeCanvas(this.$refs.canvas, this.$refs.imageCell);

                    this.canvas = this.$refs.canvas;
                    // console.log("this.canvas:" + this.canvas);
                    this.ctx = this.canvas.getContext("2d");
                    this.img = document.createElement("img");

                    const canvas = document.querySelector('#imgMask');
                    let ctx = canvas.getContext('2d');

                    let imgCanvas = document.querySelector('#imgOutput');

                    canvas.width = this.$refs.imageCell.getBoundingClientRect().width;
                    canvas.height = this.$refs.imageCell.getBoundingClientRect().height;
                    ctx.fillStyle = "#EDF2F7";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    ctx.globalCompositeOperation = 'destination-out';
                    
                    ctx.arc(
                        (canvas.width - imgCanvas.width)/2 + (imgCanvas.width/2), 
                        (canvas.height - imgCanvas.height)/2 + (imgCanvas.height/2),
                        imgCanvas.width/2, 0, 2 * Math.PI, false);
                    ctx.fill();

                    this.positionActionButton();

                    if (this.userData.userThumbnail !== undefined){

                        var canvasOut = this.$refs.imgOutput;
                        const ctxOut = canvasOut.getContext('2d');

                        let base_image = new Image();
                        base_image.src = this.userImageDataURL;
                        base_image.onload = function(){
                            ctxOut.drawImage(base_image, 0, 0, canvasOut.width, canvasOut.height);

                        } 
                    }

                    // temporarily turn on pointer-events
                    this.$refs.imgOutput.style.pointerEvents = "auto"; // none
                    this.$refs.imgOutput.style.cursor = "pointer"; 
                });
            }

        },
        positionActionButton(){
            
            let imgCanvas = this.$refs.imgOutput;
            let imageCell = this.$refs.imageCell.getBoundingClientRect();
            let photoButtonDiv = this.$refs.providePhotoButtonDiv;
        
            let photoButtonDivTop = (imgCanvas.offsetTop) + (imgCanvas.height) + 30; // the 30 is just to add a little space
        
            photoButtonDiv.style.top = photoButtonDivTop + 'px';
            photoButtonDiv.style.left = (imageCell.width/2 - photoButtonDiv.width/2) + 'px';
        },
        doEditDisplayname(){
            this.showRecipientEditScreen = false;
            this.showDisplaynameEdit = true;
        },
        doEditEmail(){
            this.showRecipientEditScreen = false;
            this.showEmailEdit = true;
        },
        doChangePassword(){
            this.showRecipientEditScreen = false;
            this.showPasswordEdit = true;
        },
        async doUpdateDBDisplayName(){
            
            const displayname = this.$refs['displayname-edit'].value;

            if (displayname === "" || displayname.length < 2 ){
                this.showPopUpOk = true;
                this.popUpMsgTitle = "Oops";     
                this.popUpMsgBody = "Your display name must be at least 2 characters please";
            } else {
                var params = {
                    userObjectId: this.user.userObjectId,
                    displayname: displayname 
                };
                await Parse.Cloud.run("saveUserFromWebApp", params);
                this.user.displayname = displayname;
                this.goBack();
            }
        },
        async doUpdateDBEmail(){
            
            const email = this.$refs['email-edit'].value;

            if (shared.validEmail(email)){
                var params = {
                    userObjectId: this.user.userObjectId,
                    email: email.toLowerCase(),
                };

                try {
                    await Parse.Cloud.run("saveUserFromWebApp", params);
                    this.user.email = email.toLowerCase();
                    this.goBack();
                } catch (e) {
                    this.showPopUpOk = true;
                    this.popUpMsgTitle = "Sorry";  

                    if (e.code == 141) { // in fact this is just the generic err code from Parse
                        this.popUpMsgBody = email + " is already in use on thankU, please try again";
                    } else {
                        this.popUpMsgBody = e.message;
                    }
                    
                }
            } else {
                this.showPopUpOk = true;
                this.popUpMsgTitle = "Oops";  
            
                if (email === ""){
                    this.popUpMsgBody = "Please enter an email address";
                } else {
                    this.popUpMsgBody = email + " is not a valid email address, please try again";
                }
            }
        },
        async doUpdateDBPassword(){

            if (this.$refs['pw-edit'].value === "" || this.$refs['pw-edit'].value.length < 5){
              this.showPopUpOk = true;
              this.popUpMsgTitle = "Oops";     
              this.popUpMsgBody = "Your password must be at least 5 characters please";
            } else if (this.$refs['pw-edit'].value !== this.$refs['pwconfirm-edit'].value){
              this.showPopUpOk = true;
              this.popUpMsgTitle = "Oops";     
              this.popUpMsgBody = "Your password confirmation doesn't match";
            } else {
                var params = {
                    userObjectId: this.user.userObjectId,
                    password: this.$refs['pw-edit'].value 
                };
                await Parse.Cloud.run("saveUserFromWebApp", params);
                this.showPopUpOk = true;
                this.popUpMsgTitle = "Done!";     
                this.popUpMsgBody = "Password successfully changed";
                this.goBack();
            }
        },
        doPopUpOk(){
            this.showPopUpOk = false;

            if (this.actionStatus === "submit") {
                this.actionStatus = "provide";
                this.goBack();
            }
        },
        doShowDashboard(){
            this.user.nextScreen = "showRecipientDashboard";
            this.$emit("next-action", this.user);
        },
        doShowAccountScreen(){

            this.user.nextScreen = "showAccountScreen";
            this.$emit("next-action", this.user);
        },
        doLogout(){
            this.user.nextScreen = "logOut";
            this.$emit("next-action", this.user);
        },
        centreRelativeCanvas(canvas, container){
            var rect = canvas.getBoundingClientRect();
            // /console.log("x: " + rect.left + " y: " + rect.top + " w: " + (rect.bottom - rect.top) + " h: " + (rect.right - rect.left));
            var tableRect = container.getBoundingClientRect();
            // /console.log("xt: " + tableRect.left + " yt: " + tableRect.top + " wt: " + (tableRect.bottom - tableRect.top) + " ht: " + (tableRect.right - tableRect.left));
            const tableWidth = tableRect.width;
            const tableHeight = tableRect.height;
            const newX = (tableWidth - rect.width) / 2 + "px";
            const newY = (tableHeight - rect.height) / 2 + "px";
            canvas.style.left = newX;
            canvas.style.top = newY;
            // /console.log("newX: " + newX + " newY: " + newY);

        },
        async saveImageDataToDB(imageData){

            try {
                var params = {
                        userObjectId: this.user.userObjectId,
                        imageData: imageData,
                    };
                const userPhotoFilename = await Parse.Cloud.run("saveUserImageToDB", params);
                window.localStorage.setItem("userPhotoFilename", userPhotoFilename);
                // console.log("userPhotoFilename::: " + userPhotoFilename);
                // /alert("SET localstorage userPhotoFilename on ACCOUNTEDIT to: " + window.localStorage.getItem("userPhotoFilename"));
            } catch (e) {
                throw e;
            }

        },
        async saveImage(){

            try {
            
            let canvas = document.getElementById("canvas");
            let canvasOutput = document.getElementById("imgOutput");
            let ctxOutput = canvasOutput.getContext('2d');

            var rect = canvas.getBoundingClientRect();
            // /console.log("Source X: " + rect.left + " Y: " + rect.top + " width: " + rect.width + " height: " + rect.height);
            var rectOutput = canvasOutput.getBoundingClientRect();
            // /console.log("Output X: " + rectOutput.left + " Y: " + rectOutput.top + " width: " + rectOutput.width + " height: " + rectOutput.height);

            let sourceOffsetX = this.sourceOffsetX;
            let sourceOffsetY = this.sourceOffsetY;
            let sourceCameraZoom = this.sourceCameraZoom;

            let sourceToOutputOffsetX = rect.left - rectOutput.left;
            let sourceToOutputOffsetY = rect.top - rectOutput.top;

            var sx = 0;
            
            if (rect.left < rectOutput.left) {
                // /console.log("rect.left < rectOutput.left");
                // /console.log("rect.left: " + rect.left + " rectOutput.left: " + rectOutput.left);
                sx = (rectOutput.left - rect.left) * sourceCameraZoom;
            } else {
                // console.log("rect.left GREATER rectOutput.left");
                sx = -(((-canvas.width/2) + (sourceOffsetX + sourceToOutputOffsetX ) )  )   ; // dont need camera zoom in this
            }

            var sy = 0;
            
            if (rect.top < rectOutput.top){
                // /console.log("rect.top < rectOutput.top");
                sy = 0 + this.offsetYNew; // I don't get this but it works!!!
            } else {
                sy = -(((-canvas.height/2) + sourceOffsetY )  ) + sourceToOutputOffsetY ;
            }

            var sWidth = 0;
            var sHeight = 0;
            
            var dWidth = 0;
            var dHeight = 0;

            var dx = 0;
            var dy = 0;

            if (canvasOutput.width <= canvas.width){
                // /console.log("canvasOutput width is narrower!");  
                sWidth = canvas.width;
                dWidth = canvas.width * sourceCameraZoom;
            } else {
                // /console.log("canvasOutput width is wider!");
                sWidth = canvasOutput.width;
                dWidth = canvasOutput.width * sourceCameraZoom;
            }
            // console.log("canvas w: " + sWidth + " output w: " + dWidth );

            if (canvasOutput.height <= canvas.height) {
                // /console.log("canvasOutput height is smaller!");
                sHeight = canvas.height;
                dHeight = canvas.height * sourceCameraZoom;
            } else {
                // /console.log("canvasOutput height is taller!");
                sHeight = canvasOutput.height;
                dHeight = canvasOutput.height * sourceCameraZoom;
            }
            // /console.log("canvas h: " + sHeight + " output h: " + dHeight );

            if (canvasOutput.width <= canvas.width || canvasOutput.height <= canvas.height){

                // /console.log("this.offsetXNew: " + this.offsetXNew);
                dx = (canvasOutput.width / 2) - (dWidth / 2 ) - (this.offsetXNew * sourceCameraZoom);
                dy = (canvasOutput.height / 2) - (dHeight / 2 ) - (this.offsetYNew * sourceCameraZoom);

                sx = 0; // so sx and sy might have been a red herring???
                sy = 0;
            } else {
                dx = (canvasOutput.width / 2) - (dWidth / 2 );
                dy = (canvasOutput.height / 2) - (dHeight / 2); 
            }

            // /console.log("-canvas.width/2: " + -canvas.width/2 + " sourceOffsetX: " + sourceOffsetX + " sourceToOutputOffsetX " + sourceToOutputOffsetX + " sourceCameraZoom: "+ sourceCameraZoom + " sx: " + sx);

            // /console.log("-canvas.height/2: " + -canvas.height/2 + " sourceOffsetY: " + sourceOffsetY + " sourceToOutputOffsetY " + sourceToOutputOffsetY + " sourceCameraZoom: " + sourceCameraZoom + " sy: " + sy);

            // /console.log("sx: " + sx + " sy: " + sy + " sWidth: " + sWidth + " sHeight: " + sHeight + " dx: " + dx + " dy: " + dy + " dWidth: " + dWidth + " dHeight: " + dHeight);

            ctxOutput.drawImage(this.sourceImage, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
            // ctxOutput.strokeRect(dx, dy, dWidth, dHeight); this was when I wanted to see what the outline of the imgOutput canvas actually was so I could work out what was happening when the final image wasn't being drawn properly

            await this.toBase64();

            } catch (e) {
                // /console.log("saveImage ERROR: " + e.message);
                throw e;
            }
        },
        /**
         * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
         * 
         * @param {HtmlElement} canvas
         * @param {int} width
         * @param {int} height
         * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
         */
        resample_single(canvas, width, height, resize_canvas) {
            var width_source = canvas.width;
            var height_source = canvas.height;
            width = Math.round(width);
            height = Math.round(height);

            var ratio_w = width_source / width;
            var ratio_h = height_source / height;
            var ratio_w_half = Math.ceil(ratio_w / 2);
            var ratio_h_half = Math.ceil(ratio_h / 2);

            var ctx = canvas.getContext("2d");
            var img = ctx.getImageData(0, 0, width_source, height_source);
            var img2 = ctx.createImageData(width, height);
            var data = img.data;
            var data2 = img2.data;

            for (var j = 0; j < height; j++) {
                for (var i = 0; i < width; i++) {
                    var x2 = (i + j * width) * 4;
                    var weight = 0;
                    var weights = 0;
                    var weights_alpha = 0;
                    var gx_r = 0;
                    var gx_g = 0;
                    var gx_b = 0;
                    var gx_a = 0;
                    var center_y = (j + 0.5) * ratio_h;
                    var yy_start = Math.floor(j * ratio_h);
                    var yy_stop = Math.ceil((j + 1) * ratio_h);
                    for (var yy = yy_start; yy < yy_stop; yy++) {
                        var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
                        var center_x = (i + 0.5) * ratio_w;
                        var w0 = dy * dy; //pre-calc part of w
                        var xx_start = Math.floor(i * ratio_w);
                        var xx_stop = Math.ceil((i + 1) * ratio_w);
                        for (var xx = xx_start; xx < xx_stop; xx++) {
                            var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
                            var w = Math.sqrt(w0 + dx * dx);
                            if (w >= 1) {
                                //pixel too far
                                continue;
                            }
                            //hermite filter
                            weight = 2 * w * w * w - 3 * w * w + 1;
                            var pos_x = 4 * (xx + yy * width_source);
                            //alpha
                            gx_a += weight * data[pos_x + 3];
                            weights_alpha += weight;
                            //colors
                            if (data[pos_x + 3] < 255)
                                weight = weight * data[pos_x + 3] / 250;
                            gx_r += weight * data[pos_x];
                            gx_g += weight * data[pos_x + 1];
                            gx_b += weight * data[pos_x + 2];
                            weights += weight;
                        }
                    }
                    data2[x2] = gx_r / weights;
                    data2[x2 + 1] = gx_g / weights;
                    data2[x2 + 2] = gx_b / weights;
                    data2[x2 + 3] = gx_a / weights_alpha;
                }
            }
            //clear and resize canvas
            if (resize_canvas === true) {
                canvas.width = width;
                canvas.height = height;
            } else {
                ctx.clearRect(0, 0, width_source, height_source);
            }

            //draw
            ctx.putImageData(img2, 0, 0);
        },
        toBase64: async function() {

            try {

                let imgOutput = document.getElementById("imgOutput");

                var ctx = imgOutput.getContext("2d");

                ctx.globalCompositeOperation = 'destination-in';

                ctx.beginPath();
                var x = imgOutput.width * 0.5; // x
                var y = imgOutput.width * 0.5; // y
                var radius = imgOutput.width * 0.5; // Arc radius
                var startAngle = 0; // Starting point on circle
                var endAngle = Math.PI*2; // End point on circle
                ctx.arc(x, y, radius, startAngle, endAngle, true);
                ctx.fill();

                var imageData = imgOutput.toDataURL("image/jpeg");

                this.user.userThumbnail = imageData; // pass back to prior screen
                this.userImageDataURL = imageData;
                
                window.localStorage.setItem("userPhotoFileData", imageData);

                imageData = imageData.replace('data:image/jpeg;base64,', '');
                this.imageData = imageData;
                // /console.log("imageData: " + this.imageData);
                await this.saveImageDataToDB(this.imageData);

            } catch (e) {
                // /console.log("toBase64 ERROR: " + e.message);
                throw e;
            }
        },
        
        draw: function(withBorders) {
            // clear the canvas
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            // some maths
            // var scaleFactor = 1 - (this.scaleSteps * .1) ;
            var scaleFactor = 1 ;
            this.scaledImageWidth = this.img.width * scaleFactor;
            this.scaledImageHeight = this.scaledImageWidth * (this.img.height/this.img.width);

            // draw the image
            // this.ctx.drawImage(
            // this.img, (top left coords of source to use (x,y)), 
            // width & height of source to use (width, height)
            // (x,y) on canvas
            // width & height on canvas
            this.ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height, this.imageX, this.imageY, this.scaledImageWidth, this.scaledImageHeight);

            this.imageRight = this.imageX + this.scaledImageWidth;
            this.imageBottom = this.imageY + this.scaledImageHeight;

            // optionally draw a box around the image (indicates "selected")
            if (withBorders) {
                this.ctx.beginPath();
                this.ctx.moveTo(this.imageX, this.imageY);
                this.ctx.lineTo(this.imageRight, this.imageY);
                this.ctx.lineTo(this.imageRight, this.imageBottom);
                this.ctx.lineTo(this.imageX, this.imageBottom);
                this.ctx.closePath();
                this.ctx.stroke();
            }
        },
        resetInput: function(){
        // clear the canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // Reset state
        this.$refs.form.reset();
        this.isImageLoaded = false;
        },
        scaleUp: function(){
        this.scaleSteps--;
        this.draw(false);
        },
        scaleDown: function(){
        this.scaleSteps++;
        this.draw(false);
        },
        getMousePos: function(evt){
            // console.log("getting mouse pos");
            var rect = this.canvas.getBoundingClientRect(), // abs. size of element
                scaleX = this.canvas.width / rect.width,    // relationship bitmap vs. element for X
                scaleY = this.canvas.height / rect.height;  // relationship bitmap vs. element for Y

            return {
            x: (evt.clientX - rect.left) * scaleX,   // scale mouse coordinates after they have
            y: (evt.clientY - rect.top) * scaleY     // been adjusted to be relative to element
            }
        },
        isImageHit: function(x, y){

            if (x > this.imageX && x < this.imageX + this.imageWidth && y > this.imageY && y < this.imageY + this.imageHeight){
                // console.log("isImageHit: " + (x > this.imageX && x < this.imageX + this.imageWidth && y > this.imageY && y < this.imageY + this.imageHeight));
            }
            
        return (x > this.imageX && x < this.imageX + this.imageWidth && y > this.imageY && y < this.imageY + this.imageHeight);
        },
        triggerInput: function(event)
        {
            // console.log("triggering input");
        event.preventDefault();
        this.$refs.fileInput.click();
        },
        previewThumbnail: async function (event) {
            var vm = this; // there is some non-critical conflict with the props definition above. The codepen didn't use it and 'this' read fine. As soon as you introduce props: for some reason this is no longer the case and you get all these vm.x doesn't have a property of... but in fact the warning does not affect correct operation
            var input = event.target;

            vm.willNeedToReload = true;
            vm.$refs.imgOutput.style.pointerEvents = "none"; // so we can pan zoom

            let canvas = document.getElementById("canvas");
            let ctx = canvas.getContext('2d');
            let canvasOutput = document.getElementById("imgOutput");
            let ctxOutput = canvasOutput.getContext('2d');

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctxOutput.clearRect(0, 0, canvasOutput.width, canvasOutput.height);

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                // Set as source
                reader.onload = function (e) {
                    vm.img.src = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);

                vm.img.onload = function() {
                    // Draw
                    vm.ctx.drawImage(vm.img, 0, 0);

                    // Grab position info
                    vm.imageWidth = vm.img.width;
                    vm.imageHeight = vm.img.height;
                    vm.imageRight = vm.imageX + vm.imageWidth;
                    vm.imageBottom = vm.imageY + vm.imageHeight

                    // /console.log("vm.canvas 2: " + vm.canvas);
                    // /console.log("vm.img.width: " + vm.img.width);
                    // /console.log("vm.img.height: " + vm.img.height);

                    vm.ctx.canvas.width = vm.img.width;
                    vm.ctx.canvas.height = vm.img.height; 

                    // /console.log("vm.ctx.canvas.width: " + vm.ctx.canvas.width);
                    // /console.log("vm.ctx.canvas.height: " + vm.ctx.canvas.height);

                    // vm.canvas.style.right = vm.canvas.style.left + vm.img.width;
                    // vm.canvas.style.bottom = vm.canvas.style.top + vm.img.height;
                    
                    // Update CTX
                    vm.draw(false);

                    let canvasOutput = document.getElementById("imgOutput");
                    let ctxOutput = canvasOutput.getContext('2d');
                    var reduceCanvasRatio = 2;

                    while ((reduceCanvasRatio > 0)) {
                        vm.resample_single(vm.canvas, vm.ctx.canvas.width/reduceCanvasRatio, vm.ctx.canvas.height/reduceCanvasRatio, true);
                        // console.log("vm.ctx.canvas.width: " + vm.ctx.canvas.width + " vm.ctx.canvas.height: " + vm.ctx.canvas.height);

                        if ((vm.ctx.canvas.width / 2) > canvasOutput.width && (vm.ctx.canvas.height / 2) > canvasOutput.height) {
                            // leave reduceCanvasRatio at 2 and go again
                        } else if ((vm.ctx.canvas.width / canvasOutput.width) > 1.1 && (vm.ctx.canvas.height / canvasOutput.height) > 1.1) {
                            if ((vm.ctx.canvas.width / canvasOutput.width) > (vm.ctx.canvas.height / canvasOutput.height)) {
                                // /console.log("chose height ratio: " + vm.ctx.canvas.height / canvasOutput.height);
                                reduceCanvasRatio = vm.ctx.canvas.height / canvasOutput.height;
                            } else {
                                // /console.log("chose width ratio: " + vm.ctx.canvas.width / canvasOutput.width);
                                reduceCanvasRatio = vm.ctx.canvas.width / canvasOutput.width;
                            }
                        
                        } else {
                            reduceCanvasRatio = 0;
                        }
                    }

                    vm.centreRelativeCanvas(vm.canvas, vm.$refs.imageCell);
                
                    // only draw image where mask is
                    vm.ctx.globalCompositeOperation = 'destination-in';

                    // // restore to default composite operation (is draw over current image)
                    // vm.ctx.globalCompositeOperation = 'source-over';

                    vm.newPanZoom(vm.canvas.toDataURL());

                    vm.actionStatus = "submit";
                    vm.providePhotoButtonText = "Save photo";
                    vm.$refs.providePhotoButton.style.backgroundColor = '#68D391';

                    // Notify component
                    vm.isImageLoaded = true;

                }
            }
        
            function handleMouseDown(e) {
                // console.log("handleMouseDown");
                var pos = vm.getMousePos(e);
                vm.startX = pos.x;
                vm.startY = pos.y;
                vm.draggingImage = vm.hoverIsDraggable;
            }

            function handleMouseUp(e) {
                
                // console.log("handleMouseUp");
                vm.draggingImage = false;
                vm.draw(false);
            }

            function handleMouseOut(e) {
                // console.log("handleMouseOut");
                handleMouseUp(e);
            }

            function handleMouseOver(e) {
                // console.log("handleMouseOver");
                
            }

            function handleMouseMove(e) 
            {
                // console.log("handlemouseMove");
                // Update cursor property var (;
                var pos = vm.getMousePos(e);
                vm.hoverIsDraggable = vm.isImageHit(pos.x, pos.y);

                if (vm.draggingImage) 
                {
                //
                vm.mouseX = pos.x;
                vm.mouseY = pos.y;

                // move the image by the amount of the latest drag
                var dx = vm.mouseX - vm.startX;
                var dy = vm.mouseY - vm.startY;
                
                // Lock image to canvas viewport
                var collidedOnLeft = vm.imageX > 0 && dx > 0;
                var collidedOnRight = vm.imageRight < vm.canvas.width && dx < 0;
                var collidedOnTop = vm.imageY > 0 && dy > 0;
                var collidedOnBottom = vm.imageBottom < vm.canvas.height && dy < 0;

                if(collidedOnLeft) {
                    vm.imageX = 0;
                    vm.imageRight = vm.scaledImageWidth;
                }
                else if(collidedOnRight) {
                    vm.imageX = vm.canvas.width - vm.scaledImageWidth;
                    vm.imageRight = vm.canvas.width;
                } else {
                    vm.imageX += dx;
                    vm.imageRight += dx;
                }
                
                if(collidedOnTop) {
                    vm.imageY = 0;
                    vm.imageBottom = vm.scaledImageHeight;
                }
                else if(collidedOnBottom) {
                    vm.imageY = vm.canvas.height - vm.scaledImageHeight;
                    vm.imageBottom = vm.canvas.height;
                } else {
                    vm.imageY += dy;
                    vm.imageBottom += dy;
                }

                // Reset the startXY for next time
                vm.startX = vm.mouseX;
                vm.startY = vm.mouseY;

                // Redraw the image with border
                vm.draw(true);
                }
            } // END MOUSEMOVE

        },
        async newPanZoom(imageVar){

            let img = imageVar;
            let canvas = document.getElementById("canvas");
            let ctx = canvas.getContext('2d');

            let cameraOffset = { x: canvas.width/2, y: canvas.height/2 }
            // /console.log("initiating cameraOffset Y: " + cameraOffset.y);
            let cameraZoom = 1;
            let MAX_ZOOM = 5;
            let MIN_ZOOM = 0.1;
            let SCROLL_SENSITIVITY = 0.0005;

            let url = img;
            let image = new Image();
            await new Promise(r => image.onload=r, image.src=url);

            ctx.drawImage(image, 0, 0);

            this.sourceImage = image;

            let vm = this;
            vm.offsetXOriginal = cameraOffset.x;
            vm.offsetYOriginal = cameraOffset.y;

            async function draw()
            {

                if (cameraOffset.x !== undefined) {
                    vm.sourceOffsetX = cameraOffset.x; // update for later
                }         
                
                if (cameraOffset.y !== undefined) {
                    vm.sourceOffsetY = cameraOffset.y;
                }

                if (cameraZoom !== undefined) {
                    vm.sourceCameraZoom = cameraZoom;
                }

                canvas.width = canvas.width
                canvas.height = canvas.height
                
                // Translate to the canvas centre before zooming - so you'll always zoom on what you're looking directly at
                ctx.translate( canvas.width / 2, canvas.height / 2 )
                ctx.scale(cameraZoom, cameraZoom)
                ctx.translate( -canvas.width / 2 + cameraOffset.x, -canvas.height / 2 + cameraOffset.y )
                ctx.clearRect(0,0, canvas.width, canvas.height)
                ctx.drawImage(image, -canvas.width / 2 , -canvas.height / 2 );
                
                requestAnimationFrame( draw )
            }

            // Gets the relevant location from a mouse or single touch event
            function getEventLocation(e)
            {
                if (e.touches && e.touches.length == 1)
                {
                    return { x:e.touches[0].clientX, y: e.touches[0].clientY }
                }
                else if (e.clientX && e.clientY)
                {
                    return { x: e.clientX, y: e.clientY }        
                }
            }

            function drawRect(x, y, width, height)
            {
                ctx.fillRect( x, y, width, height )
            }

            function drawText(text, x, y, size, font)
            {
                ctx.font = `${size}px ${font}`
                ctx.fillText(text, x, y)
            }

            let isDragging = false
            let dragStart = { x: 0, y: 0 }

            function onPointerDown(e)
            {
                isDragging = true
                dragStart.x = getEventLocation(e).x/cameraZoom - cameraOffset.x
                dragStart.y = getEventLocation(e).y/cameraZoom - cameraOffset.y
            }

            function onPointerUp(e)
            {
                isDragging = false
                initialPinchDistance = null
                lastZoom = cameraZoom
            }

            function onPointerMove(e)
            {
                if (isDragging)
                {
                    // console.log("cameraOffset.x PRE: " + cameraOffset.x);
                    cameraOffset.x = getEventLocation(e).x/cameraZoom - dragStart.x
                    // console.log("cameraOffset.x POST: " + cameraOffset.x);
                    vm.offsetXNew = vm.offsetXOriginal - cameraOffset.x;
                    // console.log("cameraOffset.y PRE: " + cameraOffset.y);
                    cameraOffset.y = getEventLocation(e).y/cameraZoom - dragStart.y
                    vm.offsetYNew = vm.offsetYOriginal - cameraOffset.y;
                    // console.log("cameraOffset.y POST: " + cameraOffset.y);
                }
            }

            function handleTouch(e, singleTouchHandler)
            {
                if ( e.touches.length == 1 )
                {
                    singleTouchHandler(e)
                }
                else if (e.type == "touchmove" && e.touches.length == 2)
                {
                    isDragging = false
                    handlePinch(e)
                }
            }

            let initialPinchDistance = null
            let lastZoom = cameraZoom

            function handlePinch(e)
            {
                e.preventDefault()
                
                let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
                let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }
                
                // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
                let currentDistance = (touch1.x - touch2.x)**2 + (touch1.y - touch2.y)**2
                
                if (initialPinchDistance == null)
                {
                    initialPinchDistance = currentDistance
                }
                else
                {
                    adjustZoom( null, currentDistance/initialPinchDistance )
                }
            }

            function adjustZoom(zoomAmount, zoomFactor)
            {
                if (!isDragging)
                {
                    if (zoomAmount)
                    {
                        cameraZoom += zoomAmount
                    }
                    else if (zoomFactor)
                    {
                        // console.log(zoomFactor)
                        cameraZoom = zoomFactor*lastZoom
                    }
                    
                    cameraZoom = Math.min( cameraZoom, MAX_ZOOM )
                    cameraZoom = Math.max( cameraZoom, MIN_ZOOM )
                    
                    // console.log(zoomAmount)
                }
            }

            canvas.addEventListener('mousedown', onPointerDown)
            canvas.addEventListener('touchstart', (e) => handleTouch(e, onPointerDown))
            canvas.addEventListener('mouseup', onPointerUp)
            canvas.addEventListener('touchend',  (e) => handleTouch(e, onPointerUp))
            canvas.addEventListener('mousemove', onPointerMove)
            canvas.addEventListener('touchmove', (e) => handleTouch(e, onPointerMove))
            canvas.addEventListener( 'wheel', (e) => adjustZoom(e.deltaY*SCROLL_SENSITIVITY))

            // Ready, set, go
            draw();
            // console.log("about to position");
            // this.positionActionButton();
            // console.log("just positioned");
        },
        
    },
    created(){

        // console.log("creating");
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);

        this.user = this.userData; // pass whole object
        // this.user.displayname = this.userData.displayname; //window.localStorage.getItem("tudis");
        // this.user.email = this.userData.email;
        // this.user.userObjectId = this.userData.userObjectId;
        // console.log("I'm created with user: " + this.user.userObjectId);

        if (this.userData.userThumbnail !== ""){
            this.userImageDataURL = this.userData.userThumbnail;
            // this.userImageDataURL = "FOREST";
        } else {
           
        }

        if (this.userData.isRecipient === false) {
            this.displaynameMsg = "Your display name is public, and will be shown to people you tip";
            this.emailMsg = "Your email address is kept private, and is used to send you tip receipts and newsletter updates";
        } else {
            this.displaynameMsg = "Your display name is public, and will be shown to people that tip you";
            this.emailMsg = "Your email address is kept private, and is used to update you about new tips and payouts";
        }

        
    },
    mounted(){
        // console.log("mounting");
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Viewed recipient account screen " + new Date().toISOString(), this.globalPushForwardInterval);

        this.calcWidths();

        // console.log("this.userData.goStraightToPhotoEdit: " + this.userData.goStraightToPhotoEdit);
        if (this.userData.goStraightToPhotoEdit === true) {
            this.userData.goStraightToPhotoEdit = false;
            this.doShowPhotoEdit();
        }
        // console.log("FINISHED mounting");
    }
}
</script>

<style scoped>
#panzoom-image{
  width: 400px;
  height: 225px;
  border: 1px solid black;
  background-position: center;
  background-size: cover;
}
</style>
