<template>
    <div @click="$emit('paymentSelected', this.paymentObjectId, this.paymentOwner, this.paymentDate, this.paymentPayoutBasis)" class="wrapper-todiv"  style="cursor:pointer;">
        <div class="userinfoframe" style="height: 8vh">
            <table align=left cellpadding=0 style="height:40%;" border=0 width=100%>
            
                <tr>

                    <td align=left width=50% class="lt-blue divl" style="font-weight: 600; height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                       <span v-if="this.usePendingColour === true" style="color: #718096">    {{ this.showProgress }} {{ this.paymentOwner }}</span> <span v-if="this.usePendingColour === false" >    {{ this.showProgress }} {{ this.paymentOwner }}</span> 
                    </td>
                    
                    <td align=right width=30% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span v-if="this.isRecipient && this.paymentOperator === '+'" style="color: #68D391">    {{ this.paymentOperator }}</span><span v-if="this.isRecipient && this.paymentOperator === '-'" style="color: #d99888">    {{ this.paymentOperator }}</span>&nbsp;<span v-if="this.usePendingColour === true" style="color: #718096">{{ this.paymentAmountReceived }}</span><span v-if="this.usePendingColour === false">{{ this.paymentAmountReceived }}</span> 
                    </td>
                    <td align=right width=20% >
                         <img class="tulogo" style="height: 1.8vh;" alt="back" src="../assets/chevronright.png">
                    </td>
                    
                </tr>
                <tr>

                    <td align=left style="height: 1vh; color: #718096; " :style="{ 'font-size': fontSizeRecordInPXToUse * 0.95 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        {{this.paymentClearingDateShort}}<span :style="{ 'font-size': fontSizeRecordInPXToUse * 0.75 + 'px', }">{{this.tippedStr}}{{ this.paymentDateShort }}</span>
                    </td>
                    <td colspan=2 align=right :style="{ 'font-size': fontSizeRecordInPXToUse * 0.95 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span style="color:#718096; padding-left:3vh" ><span v-if="this.paymentBalanceType === 'pending'" :style="{ 'font-size': fontSizeRecordInPXToUse * 0.85 + 'px', }">{{ this.paymentBalanceStr }}</span><span v-if="this.paymentBalanceType !== 'pending'" >{{ this.paymentBalanceStr }}</span></span>
                    </td>
                   
                    
                </tr>
           
            </table>
           
        </div>
        <hr>
    </div>
</template>

<script>

import shared from '../shared.js';

export default {
    name: 'BalanceRecord',
    props:  {
        paymentObjectId: String,
        paymentOwner: String,
        paymentAmountReceived: String,
        paymentDate: String,
        paymentClearingDate: String,
        paymentAmountCurrEquiv: String,
        paymentOperator: String, 
        paymentBalance: Number,
        paymentCurrencySymbol: String,
        paymentBalanceType: String,
        paymentInitialisationDate: String,
        paymentPayoutBasis: String, 
    },
    data (){
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            isRecipient: false,
            showProgress: ".",
            tippedStr: "",
            paymentClearingDateShort: "",
            paymentDateShort: "",
            paymentBalanceStr: "",
            usePendingColour: false,
        }
    },
    methods: {
        doFontSizeComputed () {
        
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        doShowProgress(){
            if (this.paymentOwner === undefined) { // see calling component
                if (this.showProgress === "...") {
                    this.showProgress = "";
                } else {
                    this.showProgress += ".";
                }
                window.setTimeout(this.doShowProgress, 200); 
            } else {
                this.showProgress = "";
            }
        },
    },
    mounted() {
        // console.log("I'm mounted: " + this.paymentObjectId);
 
        if (this.paymentClearingDate !== undefined){
            this.paymentClearingDateShort = this.paymentClearingDate.substring(0, this.paymentClearingDate.length - 5);
            
            if (this.paymentDate !== undefined) {
                this.tippedStr = " given on "
                this.paymentDateShort = this.paymentDate.substring(0, this.paymentDate.length - 5);
            } else if (this.paymentInitialisationDate !== undefined) {
                this.tippedStr = " initiated on "
                this.paymentDateShort = this.paymentInitialisationDate.substring(0, this.paymentInitialisationDate.length - 5);
            }
        }

        // console.log("on BalanceRecord: this.paymentBalance: " + this.paymentBalance + "");

        if (this.paymentBalance !== undefined){

            let paymentBalanceStr = this.paymentBalance.toFixed(2) + "";
            let balanceOperator = "";

             if (paymentBalanceStr.indexOf("-") > -1) {             
                balanceOperator = "-";
                paymentBalanceStr = paymentBalanceStr.substring(1, paymentBalanceStr.length -1);
            }

            if (this.paymentBalanceType === "pending"){
                this.paymentBalanceStr = "Pending: " + balanceOperator + this.paymentCurrencySymbol + paymentBalanceStr;
                this.usePendingColour = true;
            } else {
                this.paymentBalanceStr = "Bal: " + balanceOperator + this.paymentCurrencySymbol + paymentBalanceStr;
            }

            
        } 

        this.doShowProgress();
        this.doFontSizeComputed();
        if (window.localStorage.getItem("tuisrecipient") !== null){
            this.isRecipient = window.localStorage.getItem("tuisrecipient");
        }
    }
}
</script>

<style scoped>

    hr {
        border-width: 0.01px 0 0 0;
        border-style: single;
        border-color:  #E2E8F0;
        margin: 0px auto;
    }

</style>