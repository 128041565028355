<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
      <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 80%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=85%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 10vh">
              <div><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOK();">Ok</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
    <!-- showPopUpSummaryBreakdowns --><transition name="fade">
    <div v-show="showPopUpSummaryBreakdowns" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 80%"> 
        <!-- put in table for dev speed need to learn more about flex -->
            <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr><td height=1vh style="height:4vh; line-height:1vh" >&nbsp;</td></tr>
            <tr>
                <td height=6vh>
                    <table width="100%" border=0 style="height: 7vh">
                        <tr>
                            <!-- <td width="47%" style="outline:0.00em solid black;">
                                <div class="divl" style="height: 7vh;background-color:#FFFFFF; border-radius: 10px; padding-left:1vh; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span style="color: #718096; font-weight: 600">{{this.tipsSelectedBreakdownTitle}}</span><br><span class="lt-blue" style="font-weight: 600; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.5 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.showProgress }}{{ this.tipsSelectedBreakdownTotalAmount }}</span></div>
                            </td> -->


                            <td v-if="(this.userData.stripeBalancesObject.availableNowBalanceNumber) === 0 && this.userData.recipientsPayoutsFrequency === daily" width="47%">
                                <div class="divl" style="height: 7vh;background-color:#FFFFFF; border-radius: 10px; padding-left:1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span style="color: #718096; font-weight: 600">{{this.pendingDescription}}</span><br><span class="lt-blue" style="font-weight: 600; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.5 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.userData.stripeBalancesObject.totalBalance }}</span></div>
                            </td>
                            <td v-if="(this.userData.stripeBalancesObject.availableNowBalanceNumber) !== 0" width="47%">
                                <div class="divl" style="height: 7vh;background-color:#FFFFFF; border-radius: 10px; padding-left:1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span style="color: #718096; font-weight: 600">{{this.availableDescription}}</span><span style="color: #718096; font-weight: 200">{{this.pendingDescription}}</span><br><span class="lt-blue" style="font-weight: 600; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.5 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.userData.stripeBalancesObject.availableNowBalance }}</span><span class="lt-blue" style="font-weight: 200; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"> {{this.dividerStr}} {{ this.userData.stripeBalancesObject.allPendingPaymentsNotAvailableBalance }}</span></div>
                            </td>




                            <td width="23%" valign=center>
                                
                            </td>
                            <td width="30%">
                                <div class=divr>
                            
                                <button @click="doCancelPopover" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                                </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=1vh style="height:2vh; line-height:1vh" >&nbsp;</td></tr>

            <tr v-if="this.showPayoutsFrequency === true">
                <td height=8vh>
                    <table width="100%" border=0 style="height: 8vh">
                        <tr>
                            <td width="40%">
                                <div class="text-only-button cancel divl" style="color: #1A202C; font-weight: 100;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">You have <b>{{this.payoutsFrequencyString}}</b> payouts to your bank account {{this.onStr}} <b>{{this.payoutsFrequencyAnchorString}}</b></div>
                            </td>
                            

                        </tr>
                    </table>
                    
                </td>
            </tr>
   
            <tr>
                <td height=2vh>
                    <table width="100%" border=0 style="height: 2vh">
                        <tr>
                            <td width="40%">
                                <div class="divl" style="color: #1A202C; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{this.tipsSelectedBreakdownTitle}}</div>
                            </td>
                            <td width="20%"> 
                                &nbsp;
                            </td>
                            <td v-if="this.isLoaded===true" width="40%">
                                <div class="divr"><button class="text-only-button cancel" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doShowTipHistory();">See all</button></div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr v-if="this.tipsSelectedBreakdownArray.length !== 0"> 
                <td style="height:60vh; background-color:#FFFFFF; border-radius: 10px" >
                    <div style="height:60vh; background-color:#FFFFFF; border-radius: 10px; overflow: auto;">
                        <div style="background-color:#FFFFFF; border-radius: 10px" :key="payment.objectId" v-for="payment in this.tipsSelectedBreakdownArray" >
                            <TipRecord :paymentObjectId=payment.objectId  :paymentOtherPartyName=payment.paymentOtherPartyName :paymentOperator=payment.paymentOperator :paymentAmountReceived=payment.paymentAmountReceived :paymentDate=payment.date :paymentClearingDate=payment.clearingDate :paymentDueOrAvailable="this.dueOrAvailableStr" :paymentRunningPendingBalance=payment.runningPendingBalance :paymentRunningAvailableBalance=payment.runningAvailableBalance @paymentSelected="paymentSelected"/>
                        </div>   
                    </div>              
                </td>
            </tr>
            <tr v-if="this.tipsSelectedBreakdownArray.length === 0"> 
                <td style="height:10vh; background-color:#FFFFFF; border-radius: 10px" >
                    <div style="height:12vh; background-color:#FFFFFF; border-radius: 10px; overflow: auto;">
                        <div style="background-color:#FFFFFF; border-radius: 10px; padding-top: 4vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                           <b><table width=100% border=0 cellspacing=0 cellpadding=0><tr><td width=57% class="divr">{{this.noTipsBreakdownMsg}}</td><td width=43% class="divl">{{this.showProgress}}</td></tr></table></b>
                        </div>   
                    </div>              
                </td>
            </tr>
        </table>       
      </div>
    </div>
    <table border=0 width=85%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 10vh">
              <div><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOK();">Ok</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showRecipientDashboard -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td height=6vh>
                    <table width="100%" border=0 style="height: 7vh">
                        <tr>
                            <td width="25%">
                                <div class="divl" style="height: 6vh; display: flex;" ><button class="text-only-button cancel" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doShowHomeScreen();">Home</button></div>
                            </td>
                            <td width="50%" valign=center @click="this.doShowHomeScreen();">
                                <img class="tulogo" valign=center style="height: 7vh; padding-bottom: 1vh" alt="thankU logo" src="../assets/tutxtlogo.png">
                            </td>
                            <td width="25%" valign="center" align=right style="height: 6vh; ">
                                <table v-if="this.isLoaded===true" border=0 cellpadding=0 cellspacing=0 >
                                    <tr>
                                        <td align=right>
                                            <img @click="this.doShowAccountScreen()" valign="center" style="height: 6vh; line-height: 0.1vh; border-radius:50%"  alt="Account settings" :src="userImageDataComputed">
                                        </td>
                                    </tr>
                                     <tr>
                                        <td align=center valign=top :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.7 + 'px', }">
                                            <span class="notesInfoText" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.7 + 'px', }">{{ this.loggedInUserDisplayname }} </span>
                                        </td>
                                    </tr>
                                </table>

                               
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr>
                <td height=7vh style="outline:0.00em solid black; ">
                    <table cellspacing="0" cellpadding="0" width="100%" border=0 style="height: 7vh; ">
                        <tr>
                            <td width="47%" style="outline:0.00em solid black; cursor: pointer;" @click="doShowPopover('today', $event);">
                                <div class="divl" style="height: 7vh;background-color:#FFFFFF; border-radius: 10px; padding-left:1vh; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span style="color: #718096; font-weight: 600">Today's Tips</span><br><span class="lt-blue" style="font-weight: 600; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.5 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.showProgress }}{{ this.userData.todaysTips }}</span></div>
                            </td>
                            <td width="6%" valign=center >
                                &nbsp;
                            </td>
                            <td v-if="(this.userData.stripeBalancesObject.availableNowBalanceNumber) === 0" width="47%"  @click="doShowPopover('pending', $event);">
                                <div class="divl" style="height: 7vh;background-color:#FFFFFF; border-radius: 10px; padding-left:1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span style="color: #718096; font-weight: 600">{{this.pendingDescription}}</span><br><span class="lt-blue" style="font-weight: 600; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.5 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.showProgress }}{{ this.userData.stripeBalancesObject.totalBalance }}</span></div>
                            </td>
                            <td v-if="(this.userData.stripeBalancesObject.availableNowBalanceNumber) !== 0" width="47%"  @click="doShowPopover('pending', $event);">
                                <div class="divl" style="height: 7vh;background-color:#FFFFFF; border-radius: 10px; padding-left:1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span style="color: #718096; font-weight: 600">{{this.availableDescription}}</span><span style="color: #718096; font-weight: 200">{{this.pendingDescription}}</span><br><span class="lt-blue" style="font-weight: 600; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.5 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.showProgress }}{{ this.userData.stripeBalancesObject.availableNowBalance }}</span><span class="lt-blue" style="font-weight: 200; color: #000066" :style="{ 'font-size': fontSizeNormalInPXToUse * 1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"> {{this.dividerStr}} {{ this.userData.stripeBalancesObject.mostRecentPaymentsNotYetInTransit }}</span></div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=1vh style="height:1vh; line-height:1vh" >&nbsp;</td></tr>
            <tr>
                <td style="height:22vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="WeekTipsChart" width=100% height=100%></canvas>
                </td>
            </tr>
            <tr><td height=1vh style="height:1vh; line-height:1vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh>
                    <table width="100%" border=0 style="height: 2vh">
                        <tr>
                            <td width="40%">
                                <div class="divl" style="color: #1A202C; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Latest Tips</div>
                                 <!-- {{this.userData.strConnAccId}}  -->
                            </td>
                            <td width="20%"> 
                                &nbsp;
                            </td>
                            <td v-if="this.isLoaded===true" width="40%">
                                <div class="divr"><button class="text-only-button cancel" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doShowTipHistory();">See all</button></div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr v-if="this.latest5paymentsArray.length !== 0">
                <td style="height:40vh; background-color:#FFFFFF; border-radius: 10px" >
                    <div style="height:40vh; background-color:#FFFFFF; border-radius: 10px">
                        <div style="background-color:#FFFFFF; border-radius: 10px" :key="payment.objectId" v-for="payment in this.latest5paymentsArray" >
                            <TipRecord :paymentObjectId=payment.objectId  :paymentOtherPartyName=payment.paymentOtherPartyName :paymentOperator=payment.paymentOperator :paymentAmountReceived=payment.paymentAmountReceived :paymentDate=payment.date @paymentSelected="paymentSelected"/>
                        </div>   
                    </div>              
                </td>
            </tr>
            <tr v-if="this.latest5paymentsArray.length === 0">
                <td style="height:40vh; background-color:#FFFFFF; border-radius: 10px" >
                    <div style="height:40vh; background-color:#FFFFFF; border-radius: 10px">
                        <br><br><br>{{ this.noTipsSummaryMsg}}
                    </div>              
                </td>
            </tr>
        </table>       
      </div>
    </div>
    
</template>

<script>

const Parse = require('parse/node');

import TipRecord from './TipRecord.vue'
import shared from '../shared.js';

export default {
    name: 'RecipientDashboardScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userObjectId: String,
    },
    components: {
        TipRecord,
    },
    
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeTitle: 2.7, // vh
            fontSizeTitleLineHeight: 5, //vh
            fontSizePopUpMsg: 2.3, // vh
            fontSizePopUpMsgLineHeight: 3.4,// vh
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            showPopUpOk: false, 
            popUpMsgTitle: "",
            popUpMsgBody: "",
            paymentsArray: undefined,
            loggedInUserDisplayname: "",
            userData: shared.userDataStruct(), //so we cabn keep a uniform structure throughout
            showProgress: ".",
            userImageDataURL: "",
            refreshImage: false,
            vhMult: 1,
            isLoaded: false,
            showPopUpSummaryBreakdowns: false,
            todaysTipsArray: [],
            pendingPaymentsArray: [],
            availablePaymentsArray: [],
            tipsSelectedBreakdownTitle: "",
            tipsSelectedBreakdownTotalAmount: "",
            tipsSelectedBreakdownArray: [],
            latest5paymentsArray: [],
            noTipsBreakdownMsg: "",
            mostRecentPaymentsNotYetInTransitArray: [],
            availableDescription: "",
            pendingDescription: "Payment Pending",
            noTipsSummaryMsg: "",
            showPayoutsFrequency: false,
            payoutsFrequencyString: "daily",
            payoutsFrequencyAnchorString: "",
            onStr: "",
            dueOrAvailableStr: "",
            dividerStr : "",
            doShowProgressFlag: "off",
        }
    },
    computed: {
        userImageDataComputed() {
            // /console.log("firing userImageDataComputed");
            this.refreshImage; // just citing this causes recalculation if value changes;
            if (window.localStorage.getItem("userPhotoFileData") !== null) {
                // so the is we want to ensure we have the correct photo, and if the user switches devices (edge case) that won't be the case. We could easily sort this to check if the filename in the user's record was the same as in the localStorage but that is an expensive call. So compromise is to assume that the user is going to do something - either go to account or make a tip and we will refresh local storage at that point
                // console.log("userImageDataComputed userPhotoFileData::: " + window.localStorage.getItem("userPhotoFileData") );
                // /alert("COMPUTED from localStorage userPhotoFileData: " + window.localStorage.getItem("userPhotoFileData"));
                return window.localStorage.getItem("userPhotoFileData");
            } else {
                // console.log("just account person");     
                // /alert("simply returned accountperson.png");
                return require('@/assets/accountperson.png');
            }
        } 
    },
    methods: {
        async doShowPopover(selector){

            if (selector === "today"){

                this.showPayoutsFrequency = false;

                this.noTipsBreakdownMsg = "You have no tips so far today";
                this.tipsSelectedBreakdownArray = this.todaysTipsArray;
                this.tipsSelectedBreakdownTitle = "Today's Tips";
                this.tipsSelectedBreakdownTotalAmount = this.userData.todaysTips;
                this.showPopUpSummaryBreakdowns = true;
            } else if (selector === "pending"){

                let recipientPayoutsFrequencyChangedDate = new Date(this.userData.recipientPayoutsFrequencyChangedDate);
                console.log("recipientPayoutsFrequencyChangedDate day: " + recipientPayoutsFrequencyChangedDate.getDay());

                let compDate = new Date();
                compDate.setDate(compDate.getDate() - 45); // the problem we have is that whilst the frequencychange date is set for anyone who has recently visited their dashboard, it was not being set on them actualyl changing payout frequency until 11/11/23

                let hasChangedPayoutFrequencyRecently = false;


                if (compDate > recipientPayoutsFrequencyChangedDate){
                    console.log(" MORE than X days");
                } else {
                    hasChangedPayoutFrequencyRecently = true;
                    console.log(" X days or less since");
                }

                if (this.payoutsFrequencyString === "manual"){
                    this.doShowMakePayout();
                } else if (hasChangedPayoutFrequencyRecently === true){
                    this.doShowPayouts();
                } else if (this.userData.recipientPayoutsFrequency === "monthly" || this.userData.recipientPayoutsFrequency === "weekly"){
                    // this is safer for now, it is confusing to show the pending tip breakdown showing 'avaialble' dates without setting them in the context of exactly when they are going to be paid out
                    this.doShowPayouts();
                } else {

                    this.showPopUpSummaryBreakdowns = true;

                    // this.numberofPaymentsRetrievedWasSufficentForFullBreakdown = false; // just for dev

                    if (this.numberofPaymentsRetrievedWasSufficentForFullBreakdown === false) {
                        // there were two many payments to get on the initial Dashboard, go get them all
                        //TODO make this function! was any data changed which needs revisiting?

                        try {
                            const userPhotoFilename = window.localStorage.getItem("userPhotoFilename") !== null ? window.localStorage.getItem("userPhotoFilename") : undefined;
                            this.noTipsBreakdownMsg = "Fetching info" ;
                            this.doShowProgressFlag = "on";
                            window.setTimeout(this.doShowProgress, 200); // otherwise we see the first dot before the breakdown screen appears
                            
                            let response = await Parse.Cloud.run("fetchRecipientDashboard", {
                                userObjectId: this.userObjectId,
                                deviceSecondsFromGMT: shared.getDeviceFullObject().deviceSecondsFromGMT,
                                userPhotoFilename: userPhotoFilename,
                                fetchAllPaymentRecords: "yes",
                            });
                            this.doShowProgressFlag = "off";
                            this.showProgress = "";
                            this.userData = response;  // do this just to create space for execution  
                            // console.log("this.userData ON BREAKDOWN of DASHBOARD: " + JSON.stringify(this.userData, null, 2));

                            this.pendingPaymentsArray = this.userData.paymentsArray.filter(item => item.pendingPayment === true);
                            this.availablePaymentsArray = this.userData.paymentsArray.filter(item => item.inAvailableBalanceNotYetPaid === true);
                            this.mostRecentPaymentsNotYetInTransitArray = this.userData.paymentsArray.filter(item => item.pendingPayment === true);

                        } catch (e) {
                            console.error(e.message);
                            if (e.message === "Received an error with invalid JSON from Parse: Bad Gateway"){
                                this.popUpMsgTitle = "Sorry";
                                this.popUpMsgBody = "the thankU app is temporarily offline, we are sorry for the inconvenience - we expect to be back online shortly.";
                            } else {
                                this.popUpMsgTitle = "Sorry";
                                this.popUpMsgBody = "there was an error starting this screen, please email this error message to tech@thanku.app: " + e.message;
                            }

                            // console.log("ERROR in initDashboard: " + JSON.stringify(e, null, 2));
            
                            this.showPopUpOk = true;

                            throw e;
                        }
                    }
                

                    if (this.userData.recipientPayoutsFrequency !== undefined) {
                        if (this.userData.recipientPayoutsFrequency === "manual"){
                        // now not even possible to get here
                            this.noTipsBreakdownMsg = "You have no tips pending credit";
                            this.tipsSelectedBreakdownTitle = "Pending Credit";
                        } else if (this.userData.recipientPayoutsFrequency === "monthly" || this.userData.recipientPayoutsFrequency === "weekly" ){
                            this.noTipsBreakdownMsg = "You have no tips pending credit";
                            this.tipsSelectedBreakdownTitle = "";
                            this.tipsSelectedBreakdownArray = this.pendingPaymentsArray.concat(this.availablePaymentsArray);

                            // console.log("this.tipsSelectedBreakdownArray.length: " + this.tipsSelectedBreakdownArray.length);

                            // console.log("this.tipsSelectedBreakdownArray: " + JSON.stringify(this.tipsSelectedBreakdownArray, null, 2));

                        } else if (this.userData.stripeBalancesObject.availableNowBalanceNumber > 0){
                            // this is where the user will have changed bak to daily where there is an available balance from being either monthly weekly or manual
                            this.noTipsBreakdownMsg = "You have no tips pending payment";
                            this.tipsSelectedBreakdownTitle = "Pending Payment";
                            this.tipsSelectedBreakdownArray = this.pendingPaymentsArray.concat(this.availablePaymentsArray);

                        } else {
                            this.noTipsBreakdownMsg = "You have no tips pending payment";
                            this.tipsSelectedBreakdownTitle = "";
                            this.tipsSelectedBreakdownArray = this.pendingPaymentsArray;
                        }
                    } else {
                        // this should now never happen
                        this.noTipsBreakdownMsg = "You have no tips pending payment";
                        this.tipsSelectedBreakdownTitle = "Pending Payment";
                    }
                    
                    this.tipsSelectedBreakdownTotalAmount = this.userData.stripeBalancesObject.allPendingPaymentsNotAvailableBalance;
                    
                }

            }
            
        },
        doCancelPopover(){
            this.showPopUpSummaryBreakdowns = false;
        },
        setDis(){
            if (window.localStorage.getItem("tudis") !== null){ // we are going to replace with logged in info in a second
                this.loggedInUserDisplayname = window.localStorage.getItem("tudis");
            }
        },
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        paymentSelected(paymentObjectId){
            this.userData.fromScreen = "showRecipientDashboardScreen";
            this.userData.nextScreen = "showTipInfoRecipient";
            this.userData.selectedPaymentObjectId = paymentObjectId;
            // Parse.Cloud.run("sendEmail", {
            //     toEmail: "appnotif@thanku.app", 
            //     subject: "ALERT! Recipient " + this.userData.displayname + " viewed tip info from recipient Dashboard",
            //     body: "",
            // });
            this.$emit("next-action", this.userData);
        },
        doShowHomeScreen(){
            this.userData.fromScreen = "showRecipientDashboardScreen";
            this.userData.nextScreen = "showHomeScreen";
            this.$emit("next-action", this.userData);
        },
        doShowAccountScreen(){
            this.userData.fromScreen = "showRecipientDashboardScreen";
            this.userData.nextScreen = "showAccountScreen";

            if (this.mostRecentPaymentsNotYetInTransitArray.length > 0){
                this.userData.mostRecentPaymentsNotYetInTransitArray = this.mostRecentPaymentsNotYetInTransitArray;
            }

            // console.log("doShowAccountScreen::: " + JSON.stringify(this.userData));

            this.$emit("next-action", this.userData);
        },
        doShowTipHistory(){
            this.userData.fromScreen = "showRecipientDashboardScreen";
            this.userData.nextScreen = "showTipHistory";
            this.userData.tipHistoryMode = "recipient";
            this.$emit("next-action", this.userData);
        },
        doShowPayouts(){
            this.userData.fromScreen = "showRecipientDashboardScreen";
            this.userData.nextScreen = "showPayouts";
            this.$emit("next-action", this.userData);
        },
        doShowMakePayout(){
            this.userData.fromScreen = "showRecipientDashboardScreen";
            this.userData.nextScreen = "showMakePayout";
            this.$emit("next-action", this.userData);
        },
        doLogOut(){
            this.$emit("next-action", "logout");
        },
        doShowProgress(){

            if (this.doShowProgressFlag === "on") {

                // console.log("am progressing!  " + this.showProgress);
                if (this.showProgress === "...") {
                    this.showProgress = ".";
                } else {
                    this.showProgress += ".";
                }

                
                window.setTimeout(this.doShowProgress, 200); 
            } 
        },
        async initDashboard() {
            
            // if (window.localStorage.getItem("tuob")){
                // this.userObjectId = window.localStorage.getItem("tuob");

                const userPhotoFilename = window.localStorage.getItem("userPhotoFilename") !== null ? window.localStorage.getItem("userPhotoFilename") : undefined;

                // /alert("getting userData userPhotoFilename is: " + userPhotoFilename);

                // console.log("INIT DASH this.userObjectId: " + this.userObjectId);
                // console.log("INIT DASH userPhotoFilename: " + userPhotoFilename);
                try {

                    // MARK responsiveness end section START
                    let sendEmailForResponsiveness = false;
                    let parseDBResponsivenessArray = [];
                    let preTimeInMillis;
                    let postTimeInMillis;
                    preTimeInMillis = new Date().getTime();
                    // MARK responsiveness end section END


                    for (const payment of this.userData.paymentsArray){
                        // console.log("did this!!!!!!!!!!!!!!!!");
                        payment.otherPartyName = this.showProgress;
                    }

                    this.latest5paymentsArray = this.userData.paymentsArray;
                    this.doShowProgressFlag = "on";
                    let response = await Parse.Cloud.run("fetchRecipientDashboard", {
                        userObjectId: this.userObjectId,
                        deviceSecondsFromGMT: shared.getDeviceFullObject().deviceSecondsFromGMT,
                        userPhotoFilename: userPhotoFilename,
                        sendEmailForResponsiveness: sendEmailForResponsiveness, // at the moment this is just designed to comment in and out when testing in localhost which after all takes away domain latency

                    });

                    // MARK responsiveness end section START
                    postTimeInMillis = new Date().getTime(); parseDBResponsivenessArray.push("initDashboard on Recipient Dashboard time taken: ", preTimeInMillis, postTimeInMillis, postTimeInMillis - preTimeInMillis, "break");

                    if (sendEmailForResponsiveness === true){
                        let arrayToString = "";
                        for (let item of parseDBResponsivenessArray){
                            if (item === "break"){
                                arrayToString += "<br>";
                            } else {
                                arrayToString += item + "&nbsp;&nbsp;&nbsp;&nbsp;";
                            }
                            
                        }

                        const params = {
                        toEmail: "appalert@thanku.app",
                        subject: "Back4App CLIENT SIDE responsiveness test",
                        body: arrayToString,
                        };

                        Parse.Cloud.run("sendEmail", params);

                    }
                    // MARK responsiveness end section END


                    this.doShowProgressFlag = "off";
                    this.showProgress = "";
                    this.dividerStr = "/";
                    this.userData = response;  // do this just to create space for execution  
                    console.log("this.userData ON DASHBOARD: " + JSON.stringify(this.userData, null, 2));

                    this.noTipsSummaryMsg = "You have no tips so far";

                    if (this.userData.recipientPayoutsFrequency !== undefined) {
                        if (this.userData.recipientPayoutsFrequency === "manual") {
                            if (this.userData.stripeBalancesObject.availableNowBalanceNumber > 0){
                                this.availableDescription = "Available Now"; // the variable is a mosnormer to keep things simple
                                this.pendingDescription = " / soon";
                            } else {
                                this.pendingDescription = "Available"; // nothing's happening right now
                            }
                        } else if (this.userData.recipientPayoutsFrequency === "weekly" || this.userData.recipientPayoutsFrequency === "monthly") {
                            if (this.userData.stripeBalancesObject.availableNowBalanceNumber > 0){
                                this.availableDescription = "Next Payout"; // the variable is a mosnormer to keep things simple
                                this.pendingDescription = " / soon";
                            } else {
                                this.availableDescription = ""; // the variable is a mosnormer to keep things simple
                                this.pendingDescription = "Payment Pending";
                            }
                        } else if ((this.userData.stripeBalancesObject.availableNowBalanceNumber + this.userData.stripeBalancesObject.mostRecentPaymentsNotYetInTransit) > 0){
                            // console.log("HIT ME!"); // not sure if this is a separate case to catch yet
                            this.pendingDescription = "Payment Pending";
                        } else {
                            this.pendingDescription = "Payment Pending";
                        }

                    } else {
                        this.pendingDescription = "Payment Pending";
                    }


                    this.todaysTipsArray = this.userData.paymentsArray.filter(item => item.todaysTips === true);
                    this.pendingPaymentsArray = this.userData.paymentsArray.filter(item => item.pendingPayment === true);
                    this.availablePaymentsArray = this.userData.paymentsArray.filter(item => item.inAvailableBalanceNotYetPaid === true);
                    this.mostRecentPaymentsNotYetInTransitArray = this.userData.paymentsArray.filter(item => item.mostRecentPaymentsNotYetInTransit === true);
                    // console.log("this.mostRecentPaymentsNotYetInTransitArray.length: " + this.mostRecentPaymentsNotYetInTransitArray.length )
                    this.latest5paymentsArray = this.userData.paymentsArray.filter(item => item.latest5 === true);

                    if (response.receiveLast4 === undefined) {
                        this.doShowAccountScreen();
                        return;
                    }

                    if (response.userPhotoFilename !== undefined) {
                        window.localStorage.setItem("userPhotoFilename", response.userPhotoFilename);
                        window.localStorage.setItem("userPhotoFileData", 'data:image/' + response.userPhotoFileType + ';base64,' + response.userPhotoFileData);
                        // /alert("set localStorage userPhotoFilename ON RECIPIENT DASH: " +  window.localStorage.getItem("userPhotoFilename"));
                        // /alert("set localStorage userPhotoFileData: " + window.localStorage.getItem("userPhotoFileData"));
                    } else {
                        // /alert("did NOT set localStorage userPhotoFilename or userPhotoFileData");
                    }

                    if (response.userPhotoFileType !== undefined) {
                        window.localStorage.setItem("userPhotoFileType", response.userPhotoFileType);
                        // /alert("set localStorage userPhotoFileType: " +  window.localStorage.getItem("userPhotoFileType"));
                    } else {
                        // /alert ("did NOT set userPhotoFileType");
                    }

                    let anchorStr = "";

                    if (this.userData.recipientPayoutsFrequency !== undefined) {
                        if (this.userData.recipientPayoutsFrequency === "weekly") {
                            this.onStr = "on";
                            anchorStr = this.userData.recipientPayoutsFrequencyAnchor.substring(0, 1).toUpperCase() + this.userData.recipientPayoutsFrequencyAnchor.substring(1) + "s";
                            this.payoutsFrequencyString = this.userData.recipientPayoutsFrequency;
                            this.payoutsFrequencyAnchorString = anchorStr;
                            this.dueOrAvailableStr = "available";
                        } else if (this.userData.recipientPayoutsFrequency === "monthly") {
                            if (this.userData.recipientPayoutsFrequencyAnchor === "1" || this.userData.recipientPayoutsFrequencyAnchor === "21" || this.userData.recipientPayoutsFrequencyAnchor === "31"){
                                anchorStr = this.userData.recipientPayoutsFrequencyAnchor + "st";
                            } else if (this.userData.recipientPayoutsFrequencyAnchor === "2" || this.userData.recipientPayoutsFrequencyAnchor === "22") {
                                anchorStr = this.userData.recipientPayoutsFrequencyAnchor + "nd";
                            } else if (this.userData.recipientPayoutsFrequencyAnchor === "3" || this.userData.recipientPayoutsFrequencyAnchor === "23") {
                                anchorStr = this.userData.recipientPayoutsFrequencyAnchor + "rd";
                            } else {
                                anchorStr = this.userData.recipientPayoutsFrequencyAnchor + "th";
                            }
                            this.onStr = "on";
                            this.payoutsFrequencyString = this.userData.recipientPayoutsFrequency;
                            this.payoutsFrequencyAnchorString = "the " + anchorStr + " of the month";
                            this.dueOrAvailableStr = "available";
                        } else if (this.userData.recipientPayoutsFrequency === "daily") {
                            this.onStr = "";
                            this.payoutsFrequencyString = this.userData.recipientPayoutsFrequency;
                            this.payoutsFrequencyAnchorString = "due as shown below";
                            this.dueOrAvailableStr = "due";
                        } else {
                            this.payoutsFrequencyString = this.userData.recipientPayoutsFrequency; // will be manual
                        }

                    }

                    this.refreshImage = true;

                    const chartData = this.userData.weekTipsArray;
                    // console.log("chartData: " + chartData);
                    const ctx = document.getElementById('WeekTipsChart');
                    shared.makeChart(
                        ctx, 
                        chartData, 
                        this.userData.dayToHighlight, 
                        this.userData.userCurrencySymbol,
                        ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        "WeekTipsChart",
                        "Weekly Activity"

                    ); 
                    this.isLoaded = true;               
                    return;

                } catch (e) {
                    // /console.log("BROWSER initTipper FAILED");
                    console.error(e);
                     if (e.message === "Received an error with invalid JSON from Parse: Bad Gateway"){
                        this.popUpMsgTitle = "Sorry";
                        this.popUpMsgBody = "the thankU app is temporarily offline, we are sorry for the inconvenience - we expect to be back online shortly.";
                    } else {
                        this.popUpMsgTitle = "Sorry";
                        this.popUpMsgBody = "there was an error starting this screen, please email this error message to tech@thanku.app: " + e.message;
                    }

                    // console.log("ERROR in initDashboard: " + JSON.stringify(e, null, 2));
      
                    this.showPopUpOk = true;

                    throw e;
                } 
            // }
        },
        setDelayedTitle(){
            document.title = "thankU"; // this is so if you save it to your home screen the proposed title is just 'thankU'
        }
    },
    created(){
        // console.log("starting dashboard created " + new Date().toISOString());
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        this.setDis();
        
        this.dividerStr = "";
        this.doShowProgressFlag = "on";
        this.doShowProgress();
        try {
            this.initDashboard();
        } catch (e) {
            if (e.message === "Received an error with invalid JSON from Parse: Bad Gateway"){
                this.popUpMsgTitle = "Sorry";
                this.popUpMsgBody = "the thankU app is temporarily offline, we are sorry for the inconvenience - we expect to be back online shortly.";
            } else {
                this.popUpMsgTitle = "Sorry";
                this.popUpMsgBody = "there was an error starting this screen, please email this error message to tech@thanku.app: " + e.message;
            }
      
            this.showPopUpOk = true;
        }
        // console.log("finishing dashboard created " + new Date().toISOString());
        return;
    },
    mounted(){
        // console.log("starting dashboard mounted " + new Date().toISOString());
        this.doFontSizeComputed();
        shared.saveToUserPath(this.devEnv, "Tip history " + new Date().toISOString(), this.globalPushForwardInterval);
        
        this.vhMult = shared.setScreenHeightMultiplier();

        const chartData = [null, null, null, null, null, null, null];
        const ctx = document.getElementById('WeekTipsChart');

        shared.makeChart(
            ctx, 
            chartData,
            0, 
            "",
            ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            "WeekTipsChart",
            "Weekly Activity");
        // console.log("finishing dashboard mounted " + new Date().toISOString());

        window.setTimeout(this.setDelayedTitle, 1500); 
    }
}
</script>

<style scoped>

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1fff1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-body-text {
  vertical-align: middle;
}



</style>