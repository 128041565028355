<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 70%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div style="padding-top: 2vh"><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
          
        </table>       
      </div>
    </div>
    </transition>
    <!-- showManagementDashboard --><transition name="fade">
    <div id="topdiv" class="modal-overlay-canscroll" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; height:90vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="60%">
                                Management Dashboard
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <!-- <div v-if="this.venueName !== ''"><br>{{ this.venueName}}</div> -->
                    <canvas id="ManagementChart1" width=100% height=100%></canvas>
                    <div v-if="this.totalFees !== 0" style="font-size:70%; line-height: 150%"><br>Avg tips /mth: £{{ +this.totalTips}}<br>..where fees paid by client: {{ +this.percentageFeesPaidByClient}}%<br>..where fees deducted from tip: {{ +this.percentageFeesPaidByRecipient}}%<br>Avg fees /mth not paid by clients: £{{this.totalFeesPaidByRecipient}}<br>..as % of total tips: {{this.recipientPaidAsPercentageOfTips}}%<br>&nbsp;</div>
                </td>
            </tr>
             <tr>
                <td>
                    
                    <div v-if="this.totalFees !== 0" style="font-size:90%; line-height: 150%"><br>Avg monthly fees: £{{ +this.totalFees}}<br>..paid by client: £{{ +this.totalFeesPaidByClient}}<br>Paid by staff: £{{ +this.totalFeesPaidByRecipient}} </div>
                     <div v-if="this.venueName !== ''"><br>{{ this.venueName}}</div>
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart2" width=100% height=100%></canvas>
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart3" width=100% height=100%></canvas>
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart4" width=100% height=100%></canvas>
                </td>
            </tr>
             <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart5" width=100% height=100%></canvas>
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart6" width=100% height=100%></canvas>
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart7" width=100% height=100%></canvas>
                </td>
            </tr>
            <tr>
                <td style="height:30vh; background-color:#FFFFFF; border-radius: 10px">
                    <canvas id="ManagementChart8" width=100% height=100%></canvas>
                </td>
            </tr>

        </table> 
        </div>
        
      </div>
      </transition>
    
</template>

<script>

const Parse = require('parse/node');

import shared from '../shared.js';


export default {
    name: 'AccountManagementDashboard',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    data () {
        return {
            fontSizeNormal: 3.0, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.6,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 3.0, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 3.0,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            showPopUpTwoOptions: false,
            PopUpTwoOptionsTitle: "",
            PopUpTwoOptionsMessage: "",  
            option1ButtonText: "",
            option2ButtonText: "",
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            suppressOk: false,
            vhMult: 1,
            totalFeesPaidByClient: "",
            totalFeesPaidByRecipient: "",
            totalFees: "",
            totralTips: "",
            percentageFeesPaidByClient: "",
            percentageFeesPaidByRecipient: "",
            venueName: "",
            recipientPaidAsPercentageOfTips: "",
            
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        
        goBack(){
            this.userData.nextScreen = "showAccountScreen";
            // console.log("this.userData::: " + JSON.stringify(this.userData, null, 2));
            this.$emit("next-action", this.userData);
        },

        doPopUpOk(){
            this.showPopUpOk = false;
        },
        doShowAccountScreen(){
            // console.log("leaving AccountManagementDashboard screen");
            this.userData = this.user;
            this.userData.nextScreen = "showAccountScreen"
            this.$emit("next-action", this.userData);
        },
        async getPercentagePayingFeesObject(){
            const percentagePayingFeesObject = await Parse.Cloud.run("getPercentagePayingFees", undefined);   
            this.showPopUpOk = false;
            this.suppressOk = false;

            this.venueName = percentagePayingFeesObject.venueName;
            this.totalFeesPaidByClient = +(percentagePayingFeesObject.totalFeesPaidByClient/6).toFixed(0);
            this.totalFeesPaidByRecipient = +(percentagePayingFeesObject.totalFeesPaidByRecipient/6).toFixed(0);
            this.totalFees = +(percentagePayingFeesObject.totalFees/6).toFixed(0);
            this.totalTips = +(percentagePayingFeesObject.totalTips/6).toFixed(0);
            this.percentageFeesPaidByClient = percentagePayingFeesObject.percentageFeesPaidByClient;
            this.percentageFeesPaidByRecipient = percentagePayingFeesObject.percentageFeesPaidByRecipient;
            this.recipientPaidAsPercentageOfTips = +(this.totalFeesPaidByRecipient / this.totalTips * 100).toFixed(1);
            
            // console.log("percentagePayingFeesObject: " + JSON.stringify(percentagePayingFeesObject, null, 2));
            this.populateChart('ManagementChart1', percentagePayingFeesObject.weekDatePerCentValueArray, percentagePayingFeesObject.weekDateArray, "% paid fees");
        },
        async getPercentageSettingDisplaynameObject(){
            const percentageSettingDisplaynameObject = await Parse.Cloud.run("getPercentageSettingDisplayname", undefined);   
            this.showPopUpOk = false;
            this.suppressOk = false;
            this.populateChart('ManagementChart2', percentageSettingDisplaynameObject.weekDatePerCentValueArray, percentageSettingDisplaynameObject.weekDateArray, "% set Displayname");
        },
        async getHasSetEmailObject(){
            // console.log("calling getHasSetEmailObject");
            const hasSetEmailObject = await Parse.Cloud.run("getHasSetEmailObject", undefined);   
            // console.log("returned getHasSetEmailObject");
            this.showPopUpOk = false;
            this.suppressOk = false;
            this.populateChart('ManagementChart3', hasSetEmailObject.weekDatePerCentValueArray, hasSetEmailObject.weekDateArray, "% set email");
        },
        async getHasSetPasswordObject(){
            // console.log("calling getHasSetPasswordObject");
            const hasSetPasswordObject = await Parse.Cloud.run("getHasSetPasswordObject", undefined);   
            // console.log("returned getHasSetPasswordObject");
            this.showPopUpOk = false;
            this.suppressOk = false;
            this.populateChart('ManagementChart4', hasSetPasswordObject.weekDatePerCentValueArray, hasSetPasswordObject.weekDateArray, "% set password");
        },
        async getPercentageCancelsObject(){
            // console.log("calling getPercentageCancelsObject");
            const percentageCancelsObject = await Parse.Cloud.run("getPercentageCancelsObject", undefined);   
            // console.log("returned getPercentageCancelsObject");
            // console.log("getPercentageCancelsObject:\n" + JSON.stringify(percentageCancelsObject, null, 2));
            this.showPopUpOk = false;
            this.suppressOk = false;
            this.populateChart('ManagementChart5', percentageCancelsObject.weekDatePerCentValueArray, percentageCancelsObject.weekDateArray, "% cancelled");
        },
        async getPercentageNativePaymentsObject(){
            // console.log("calling getPercentageNativePaymentsObject");
            const percentageNativePaymentsObject = await Parse.Cloud.run("getPercentageNativePaymentsObject", undefined);   
            // console.log("returned getPercentageNativePaymentsObject");
            // console.log("getPercentageNativePaymentsObject:\n" + JSON.stringify(percentageNativePaymentsObject, null, 2));
            this.showPopUpOk = false;
            this.suppressOk = false;
            this.populateChart('ManagementChart6', percentageNativePaymentsObject.weekDatePerCentValueArray, percentageNativePaymentsObject.weekDateArray, "% native app tips");
        },
        populateChart(chartName, chartData, weekDateArray, legendTitle){
             // const chartData = this.userData.weekTipsArray;
            // const chartData = managementData.percentagePayingFeesObject.weekDatePerCentValueArray;
            // /console.log("chartData: " + chartData);
            const ctx = document.getElementById(chartName);
            var cleanedWeekDateArray = [];
            for (let dateStr of weekDateArray){
                dateStr = dateStr.replace("/2022", "");
                cleanedWeekDateArray.push(dateStr);
            }

            shared.makeChart(
                ctx, 
                chartData, 
                0, 
                "%",
                cleanedWeekDateArray,
                chartName,
                legendTitle,
            );  
        },
    },
    async created(){

        // console.log("creating AccountManagementDashboard");
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
                
    },
    async mounted(){
        // console.log("mounting AccountManagementDashboard");

        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();

        this.popUpMsgTitle = "Just coming...";
        this.popUpMsgBody = "getting the data";
        this.suppressOk = true;
        this.showPopUpOk = true;
        
        // const managementData = await Parse.Cloud.run("getManagementDash", params);     
        // alert("Management data: " + JSON.stringify(managementData.percentagePayingFeesObject, null, 2));

        // not asyncs they come when they come 
        await this.getPercentagePayingFeesObject();
        // this.showPopUpOk = false;
        
        
        
        
        await this.getPercentageSettingDisplaynameObject();
        await this.getHasSetEmailObject();
        await this.getHasSetPasswordObject();
        await this.getPercentageCancelsObject();
        await this.getPercentageNativePaymentsObject();
       
        // const chartData2 = managementData.percentageSettingDisplaynameObject.weekDatePerCentValueArray;
        // const ctx2 = document.getElementById('ManagementChart2');
        // var cleanedWeekDateArray = [];
        // for (const dateStr of managementData.percentageSettingDisplaynameObject.weekDateArray){
        //     dateStr = dateStr.replace("/2022", "");
        //     cleanedWeekDateArray.push(dateStr);
        // }
        // shared.makeChart(
        //     ctx2, 
        //     chartData2, 
        //     0, 
        //     "%",
        //     cleanedWeekDateArray,
        //     "ManagementChart2",
        //     "% set Displayname",
        // );   
        // const chartData3 = managementData.hasSetEmailObject.weekDatePerCentValueArray;
        // const ctx3 = document.getElementById('ManagementChart3');
        // var cleanedWeekDateArray = [];
        // for (const dateStr of managementData.hasSetEmailObject.weekDateArray){
        //     dateStr = dateStr.replace("/2022", "");
        //     cleanedWeekDateArray.push(dateStr);
        // }
        // shared.makeChart(
        //     ctx3, 
        //     chartData3, 
        //     0, 
        //     "%",
        //     cleanedWeekDateArray,
        //     "ManagementChart3",
        //     "% set Displayname",
        // );  
        // const chartData4 = managementData.hasSetPasswordObject.weekDatePerCentValueArray;
        // const ctx4 = document.getElementById('ManagementChart4');
        // var cleanedWeekDateArray = [];
        // for (const dateStr of managementData.hasSetPasswordObject.weekDateArray){
        //     dateStr = dateStr.replace("/2022", "");
        //     cleanedWeekDateArray.push(dateStr);
        // }
        // shared.makeChart(
        //     ctx4, 
        //     chartData4, 
        //     0, 
        //     "%",
        //     cleanedWeekDateArray,
        //     "ManagementChart4",
        //     "% set Displayname",
        // );        
    }
}
</script>

``