<template>
    <div @click="$emit('paymentSelected', this.paymentObjectId, this.paymentOtherPartyName, this.paymentDate)" class="wrapper-todiv"  style="cursor:pointer;">
        <div class="userinfoframe" style="height: 8vh">
            <table align=left cellpadding=0 style="height:40%;" border=0 width=100%>
            
                <tr>

                    <td align=left width=60% class="lt-blue divl" style="font-weight: 600; height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        {{ this.showProgress }} {{ this.paymentOtherPartyName }} 
                    </td>
                    
                    <td align=right width=20% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span v-if="this.isRecipient" style="color: #68D391">{{ this.paymentOperator }}</span>&nbsp;{{ this.paymentAmountReceived }} 
                    </td>
                    <td align=right width=20% >
                         <img class="tulogo" style="height: 1.8vh;" alt="back" src="../assets/chevronright.png">
                    </td>
                    
                </tr>
                <tr v-if="this.paymentAmountCurrEquiv !== undefined">
                    <!-- we are using this as a flag to take another couple of columns for space if not present, which it won't be for recipient records -->

                    <td align=left style="height: 1vh; color: #718096; " :style="{ 'font-size': fontSizeRecordInPXToUse * 0.95 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        {{ this.paymentDate }}{{this.dueStr}}{{this.paymentClearingDateShort}}
                    </td>
                    <td colspan=2 align=center :style="{ 'font-size': fontSizeRecordInPXToUse * 0.95 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span style="color:#718096; padding-left:6vh" >{{ this.paymentAmountCurrEquiv }}</span>
                    </td>
                </tr>

                <tr v-if="this.paymentAmountCurrEquiv === undefined">
                    <!-- we are using this as a flag to take another couple of columns for space if not present, which it won't be for recipient records -->

                    <td colspan=2 align=left style="height: 1vh; color: #718096; " :style="{ 'font-size': fontSizeRecordInPXToUse * 0.95 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        {{ this.paymentDate }}{{this.dueStr}}{{this.paymentClearingDateShort}}
                    </td>
                    <td align=center>
                        <span style="color:#718096; padding-left:6vh" ><span :style="{ 'font-size': fontSizeRecordInPXToUse * 0.9 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.paymentRunningPendingBalance }}</span><span :style="{ 'font-size': fontSizeRecordInPXToUse * 0.95 + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }"><b>{{this.paymentRunningAvailableBalance}}</b></span></span>
                    </td>
                </tr>
           
            </table>
           
        </div>
        <hr>
    </div>
</template>

<script>

import shared from '../shared.js';

export default {
    name: 'TipRecord',
    props:  {
        paymentObjectId: String,
        paymentOtherPartyName: String,
        paymentAmountReceived: String,
        paymentDate: String,
        paymentClearingDate: String,
        paymentAmountCurrEquiv: String,
        paymentOperator: String,
        paymentDueOrAvailable: String,
        paymentRunningPendingBalance: String,
        paymentRunningAvailableBalance: String,
    },
    data (){
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            isRecipient: false,
            showProgress: ".",
            dueStr: "",
            paymentClearingDateShort: "",
        }
    },
    methods: {
        doFontSizeComputed () {
        
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        doShowProgress(){
            if (this.paymentOtherPartyName === undefined) { // see calling component
                if (this.showProgress === "...") {
                    this.showProgress = "";
                } else {
                    this.showProgress += ".";
                }
                window.setTimeout(this.doShowProgress, 200); 
            } else {
                this.showProgress = "";
            }
        },
    },
    mounted() {
        // console.log("I'm mounted: " + this.paymentObjectId);

        // console.log("this.paymentDueOrAvailable: " + this.paymentDueOrAvailable);
 
        if (this.paymentClearingDate !== undefined){

            if (this.paymentDueOrAvailable === "available"){
                this.dueStr = " available ";
            } else {
                this.dueStr = " due ";
            }
            
            if (this.paymentClearingDate === "now"){
                this.paymentClearingDateShort = this.paymentClearingDate;
            } else {
                this.paymentClearingDateShort = this.paymentClearingDate.substring(0, this.paymentClearingDate.length - 5);
            }
        }

        this.doShowProgress();
        this.doFontSizeComputed();
        if (window.localStorage.getItem("tuisrecipient") !== null){
            this.isRecipient = window.localStorage.getItem("tuisrecipient");
        }
    }
}
</script>

<style scoped>

    hr {
        border-width: 0.01px 0 0 0;
        border-style: single;
        border-color:  #E2E8F0;
        margin: 0px auto;
    }

</style>