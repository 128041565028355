<template>
    <div @click="$emit('payment-selected', this.paymentTransactionId, this.paymentTipperName, this.paymentArrivalDate, this.paymentAmountReceived, this.paymentObjectId)" class="wrapper-todiv"  :style="{ 'cursor': this.pointerStr }"> 
        <div class="userinfoframe" style="height: 6vh">
            <table align=left cellpadding=0 style="height:30%;" border=0 width=100%>
            
                <tr>

                    <td align=left width=30% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        {{ this.showProgress }} 
                        <span v-if="this.paymentDate==='Total'"><b>{{ this.paymentDate }}</b></span>
                        <span v-if="this.paymentDate!=='Total'">{{ this.paymentDate }}</span>
                    </td>
                    
                    <td align=left width=40%  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span style="color:#718096; padding-right:2vh" >{{ this.paymentTipperName }}</span>
                    </td>
                    <td align=right width=20% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span v-if="this.paymentDate==='Total'"><b><span style="color: #68D391">{{ this.paymentOperator }}</span>&nbsp;{{ this.paymentAmountReceived }}</b></span>
                        <span v-if="this.paymentDate!=='Total'"><span style="color: #68D391">{{ this.paymentOperator }}</span>&nbsp;{{ this.paymentAmountReceived }}</span>
                    </td>
                    <td align=right width=10% >
                         <span v-if="this.paymentDate!=='Total'"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom:0.3vh" alt="back" src="../assets/chevronright.png"></span>
                         <span v-if="this.paymentDate==='Total'">&nbsp;</span>
                    </td>
                    
                </tr>
           
            </table>
           
        </div>
        <hr>
    </div>
</template>

<script>

import shared from '../shared.js';

export default {
    name: 'payoutBreakdownRecord',
    props:  {
        paymentObjectId: String,
        paymentAmountReceived: String,
        paymentStatus: String,
        paymentDate: String,
        paymentArrivalDate: String,
        paymentOperator: String,
        paymentTipperName: String,
        paymentTransactionId: String,
    },
    data (){
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            showProgress: ".",
            pointerStr: "pointer",
        }
    },
    methods: {
        doFontSizeComputed () {
        
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        doShowProgress(){

            if (this.paymentAmountReceived === undefined) {
                if (this.showProgress === "...") {
                    this.showProgress = "";
                } else {
                    this.showProgress += ".";
                }
                window.setTimeout(this.doShowProgress, 200); 
            } else {
                this.showProgress = "";
            }
        },
    },
    mounted() {
        this.doShowProgress();
        this.doFontSizeComputed();
 
        // console.log("paymentObjectId: " + this.paymentObjectId);
        // console.log("paymentTransactionId: " + this.paymentTransactionId);

        if (this.paymentDate === "Total"){
            this.pointerStr = "normal";
        } 
 
        // console.log("this.pointerStr: " + this.pointerStr);
        
    }
}
</script>

<style scoped>

    hr {
        border-width: 0.01px 0 0 0;
        border-style: single;
        border-color:  #E2E8F0;
        margin: 0px auto;
    }

</style>