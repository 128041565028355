<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 90%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 cellpadding=0 cellspacing=0 width=95%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" style="padding-left: 0.3vh; padding-right: 0.3vh" :style="{ 'font-size': this.fontSizePopUpMsg  + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div style="padding-top: 2vh"><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
          
        </table>       
      </div>
    </div>
    </transition>
    <!-- showChoosePaymentMethod --><transition name="fade">
    <div id="topdiv" class="modal-overlay" style="width: 100%">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2%; padding-right: 2%; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="60%">
                                Manage Payouts
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
           
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh align=center>
                    <table width="95%" cellpadding=0 cellspacing=0 border=0 style="height: 2vh">
                        <tr>
                            <td width="85%">
                                <div class="divc" style="color: #1A202C; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Choose your payout frequency</div>
                            </td>
                            <td width=15%>
                                <img style="height: 3.5vh; width: 3.5vh; cursor: pointer" @click="this.payoutsFrequencyInfo()" alt="help on choosing payouts frequency"  src="../assets/help.png">
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr v-show="this.readyToDisplay===true">
                <td>
                    <table align=center width=60% max-width=100% border=0 cellspacing="0" cellpadding="0" >
                        <tr style="height: 8vh;">
                            <td width=4%>
                                &nbsp;
                            </td>
                            <td width=50% >
                                <div class="divl">Daily</div>
                            </td>
                            
                            <td width=42% align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection1();" ref="switch1">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td width=4%>
                                &nbsp;
                            </td>
                        </tr>
                        <tr style="height: 8vh;">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                <div class="divl">Weekly</div>
                            </td>
                            <td align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection2();" ref="switch2">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-show="this.showWeeklySelector === true" style="height: 4vh;">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                <div class="divl" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">Day of week</div>
                            </td>
                            
                            <td>
                                <div style="height: 3vh; display: flex;">
                                    <select style="height: 3vh; min-width: 75%; " class="standard-textbox input" name="weekdaySelected" ref="weekdaySelected" @change="doDetectSelectWeekdayChange()" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">
                                    <option value="monday">Mon</option>
                                    <option value="tuesday">Tue</option>
                                    <option value="wednesday">Wed</option>
                                    <option value="thursday">Thu</option>
                                    <option value="friday">Fri</option>
                                    
                                    </select>
                                </div>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-show="this.showWeeklySelector === true" style="height: 1.5vh; line-height: 0px; font-size:0px; padding-top: 0px; padding-bottom: 0px"><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                        <tr style="height: 8vh;">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                <div class="divl">Monthly</div>
                            </td>
                            <td align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection3();" ref="switch3">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-show="this.showMonthlySelector === true" style="height: 4vh;">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                <div class="divl" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">Day of month</div>
                            </td>
                            
                            <td>
                                <div style="height: 3vh; display: flex;">
                                    <select style="height: 3vh; min-width: 75%; " class="standard-textbox input" name="dayOfMonthSelected" ref="dayOfMonthSelected" @change="doDetectSelectDayOfMonthChange()" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">
                                    <option value="1">1st</option>
                                    <option value="2">2nd</option>
                                    <option value="3">3rd</option>
                                    <option value="4">4th</option>
                                    <option value="5">5th</option>
                                    <option value="6">6th</option>
                                    <option value="7">7th</option>
                                    <option value="8">8th</option>
                                    <option value="9">9th</option>
                                    <option value="10">10th</option>
                                    <option value="11">11th</option>
                                    <option value="12">12th</option>
                                    <option value="13">13th</option>
                                    <option value="14">14th</option>
                                    <option value="15">15th</option>
                                    <option value="16">16th</option>
                                    <option value="17">17th</option>
                                    <option value="18">18th</option>
                                    <option value="19">19th</option>
                                    <option value="20">20th</option>
                                    <option value="21">21st</option>
                                    <option value="22">22nd</option>
                                    <option value="23">23rd</option>
                                    <option value="24">24th</option>
                                    <option value="25">25th</option> 
                                    <option value="26">26th</option>
                                    <option value="27">27th</option>
                                    <option value="28">28th</option>
                                    <option value="29">29th</option>
                                    <option value="30">30th</option>
                                    <option value="31">31st</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-show="this.showWeeklySelector === true" style="height: 1.5vh; line-height: 0px; font-size:0px; padding-top: 0px; padding-bottom: 0px"><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                        <tr style="height: 8vh;">
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                <div class="divl">Manual</div>
                            </td>
                            <td align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection4();" ref="switch4">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td width=4%>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-show="this.showMakePayout === true">
                            <td colspan=3>
                                <button class="widebtn" style="height: 6vh" ref="makePayout" @click="doMakePayout($event);">Make a payout
            
                                </button>
                            </td>
                        </tr>

                    </table>
                </td>
            </tr>
            <tr>
                <td> <br>
                </td>
            </tr>

            <tr>
                <td height=2vh align=center>
                    <table width="90%" cellspacing=5 cellpadding=0 border=0 style="height: 2vh">
                        <tr>
                            <td colspan=2 width="100%">
                                <div class="divl" style="color: #1A202C; font-weight: 600; padding-bottom: 1vh;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span v-if="this.userData.receiveLast4===undefined">Connect bank account</span><span v-else>Paying to bank account:<br> </span></div>

                            </td>
                        </tr>
                        <tr>
                            <td width="70%">

                                <div class="divl" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"> {{ this.userData.accountNameAbbr }}  {{ this.userData.receiveLast4 }}</div>
                            </td>
                            <td width="30%">
                                <button class="widebtn" style="font-size: 14px; width: 100%; height:5vh" @click="this.doChangeBankAccount()">Change</button>
                            </td>
                              
                        </tr>
                    </table>
                    
                </td>
            </tr>
          
            
            <tr><td height=70vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>  
        </table> 
        
      </div>
    </div>
    </transition>

    
</template>

<script>

const Parse = require('parse/node');

import shared from '../shared.js';
import {loadStripe} from '@stripe/stripe-js/pure';

var stripe; 

export default {
    name: 'PayoutsManageScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    data () {
        return {
            fontSizeNormal: 3.0, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.6,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 3.0, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 3.0,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            fontSizeTitle: 2.7, // vh
            fontSizeTitleLineHeight: 5, //vh
            fontSizePopUpMsg: 2.3, // vh
            fontSizePopUpMsgLineHeight: 3.4,// vh
            user: {},
            userImageDataURL: "",
            showPopUpTwoOptions: false,
            PopUpTwoOptionsTitle: "",
            PopUpTwoOptionsMessage: "",  
            option1ButtonText: "",
            option2ButtonText: "",
            showRecipientEditScreen: true,
            showChoosePaymentMethods: true,
            showEmailEdit: false,
            showPasswordEdit: false,
            PaymentMethodsMsg: "",
            emailMsg: "",
            pwMsg: "",
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            showPhotoEdit: false,
            dataURL: "",
            applePayAvailable: false,
            googlePayAvailable: false,
            showApplePay: false,
            showGooglePay: false,
            applePayMessage: "",
            readyToDisplay: false,
            showCardInput: false,
            willHideCardInput: false,
            
            vhMult: 1,

            showMakePayout: false,
            showWeeklySelector: false,
            showMonthlySelector: false,
            
            stripePublishableKey: process.env.VUE_APP_STRIPE_ENV === "dev" ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LIVE,

            stripeEndPoint: process.env.VUE_APP_STRIPE_ENV === "dev" ? process.env.VUE_APP_STRIPE_ENDPOINT_TEST : process.env.VUE_APP_STRIPE_ENDPOINT_LIVE,
            
        }
    },
    computed: {
        containerStyles: function () {
            return {
                width: this.width,
                height: this.height
            };
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        
        goBack(){
           
            // console.log("leaving PayoutsManage screen");
            // console.log("this.userData.fromScreen: " + this.userData.fromScreen);

            if (this.userData.fromScreen === "showPayoutsManage"){
                // back must be to Account Screen
                this.userData.nextScreen = "showPayouts";
            } else {
                this.userData.nextScreen = this.userData.fromScreen;
            }
            
            this.$emit("next-action", this.userData);
   
        },
        doChangeBankAccount(){
            // this.showAccountScreen = false;
            this.userData.fromScreen = "showPayoutsManage";
            this.userData.nextScreen = "connectBankAccount";

            // console.log("ON PAYOUTS MANAGER this.userData.fromScreen: " + this.userData.fromScreen);
            this.$emit("next-action", this.userData);
        },  

        doMakePayout(){
            this.userData.fromScreen = "showPayoutsManage";
            this.userData.nextScreen = "showMakePayout";

            this.$emit("next-action", this.userData);
        },
        

        doPopUpOk(){
            this.showPopUpOk = false;

            if (this.willHideCardInput === true) {
                this.showCardInput = false;
            }
        },

        async toggleSelection1(){
            // console.log("toggling");
            if (this.$refs.switch1.checked === true){
                // console.log("1");
                this.$refs.switch2.checked = false;
                this.$refs.switch3.checked = false;
                this.$refs.switch4.checked = false;
                this.showMakePayout = false;
                this.showWeeklySelector = false;
                this.showMonthlySelector = false;
                
                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "daily"}); 
                    this.userData.recipientPayoutsFrequency = "daily";
                    this.userData.recipientPayoutsFrequencyAnchor = "";
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
            } else {
                this.$refs.switch2.checked = true;
                 try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "weekly", "payoutsFrequencyAnchor" : this.$refs.weekdaySelected.value}); 
                    this.userData.recipientPayoutsFrequency = "weekly";
                    this.userData.recipientPayoutsFrequencyAnchor = this.$refs.weekdaySelected.value;
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
                this.showMakePayout = false;
                this.showWeeklySelector = true;
                this.showMonthlySelector = false;
            }
        },
        async toggleSelection2(){
            if (this.$refs.switch2.checked === true){
                // console.log("2");
                this.$refs.switch1.checked = false;
                this.$refs.switch3.checked = false;
                this.$refs.switch4.checked = false;
                this.showMakePayout = false;
                this.showWeeklySelector = true;
                this.showMonthlySelector = false;

                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "weekly", "payoutsFrequencyAnchor" : this.$refs.weekdaySelected.value});
                    this.userData.recipientPayoutsFrequency = "weekly";
                    this.userData.recipientPayoutsFrequencyAnchor = this.$refs.weekdaySelected.value; 
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
            } else {
                this.$refs.switch1.checked = true;
                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "daily"}); 
                    this.userData.recipientPayoutsFrequency = "daily";
                    this.userData.recipientPayoutsFrequencyAnchor = "";
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
                this.showMakePayout = false;
                this.showWeeklySelector = false;
                this.showMonthlySelector = false;
            }
        },
        async toggleSelection3(){
            if (this.$refs.switch3.checked === true){
                // console.log("3");
                this.$refs.switch1.checked = false;
                this.$refs.switch2.checked = false;
                this.$refs.switch4.checked = false;
                this.showMakePayout = false;
                this.showWeeklySelector = false;
                this.showMonthlySelector = true;

                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "monthly", "payoutsFrequencyAnchor" : this.$refs.dayOfMonthSelected.value}); 
                    this.userData.recipientPayoutsFrequency = "monthly";
                    this.userData.recipientPayoutsFrequencyAnchor = this.$refs.dayOfMonthSelected.value + "";
                    // console.log("JUST CHAANGED MY DATE TO " + this.$refs.dayOfMonthSelected.value); 
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
                
            } else {
                this.$refs.switch1.checked = true;
                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "daily"}); 
                    this.userData.recipientPayoutsFrequency = "daily";
                    this.userData.recipientPayoutsFrequencyAnchor = "";
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
                this.showMakePayout = false;
                this.showWeeklySelector = false;
                this.showMonthlySelector = false;
            }
        },

        async toggleSelection4(){
            if (this.$refs.switch4.checked === true){
                // console.log("4");
                this.$refs.switch1.checked = false;
                this.$refs.switch2.checked = false;
                this.$refs.switch3.checked = false;
                this.showMakePayout = true;
                this.showWeeklySelector = false;
                this.showMonthlySelector = false;

                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "manual"}); 
                    this.userData.recipientPayoutsFrequency = "manual";
                    this.userData.recipientPayoutsFrequencyAnchor = "";
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
                
            } else {
                this.showMakePayout = false;
                this.showWeeklySelector = false;
                this.showMonthlySelector = false;
                this.$refs.switch1.checked = true;
                try {
                    await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "daily"}); 
                    this.userData.recipientPayoutsFrequency = "daily";
                    this.userData.recipientPayoutsFrequencyAnchor = "";
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
            }
        },

        async assignModuleVars() {


            // stripe = await Stripe(this.stripePublishableKey); this was the old way of doing it where we used <script on index.html
            stripe = await loadStripe(this.stripePublishableKey); // now we just bring in when needed
            // console.log("STRIPE KEY:::" + this.stripePublishableKey); // /console.log(stripe);
            // console.log("process.env.VUE_APP_STRIPE_ENV === dev :::: " + (process.env.VUE_APP_STRIPE_ENV === "dev"));
            // console.log(process.env.VUE_APP_STRIPE_ENV);
           
            return;

        },

        payoutsFrequencyInfo(){
            
            // console.log("doing payoutsFrequencyInfo");

            this.popUpMsgTitle = "Payout frequency";         
            this.popUpMsgBody = "Choose how frequently your tips are paid to your bank account - 'Daily' automatically pays 3 working days after the tip was given, on a rolling basis, 'Weekly' is similarly weekly automatic including tips up to 3 business days preceding, monthly likewise. When you set your schedule to 'Manual', payouts are no longer automatic - when you want to get paid, you manually request a bank transfer from your available balance.";
            this.showPopUpOk = true;

        },

        async doDetectSelectWeekdayChange(){

            // console.log("this.$refs.weekdaySelected.value:: " + this.$refs.weekdaySelected.value);
            
            try {
                await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "weekly", "payoutsFrequencyAnchor" : this.$refs.weekdaySelected.value}); 
                this.userData.recipientPayoutsFrequency = "weekly";
                this.userData.recipientPayoutsFrequencyAnchor = this.$refs.weekdaySelected.value;
            } catch (e) {
                this.popUpMsgTitle = "Sorry";
                this.popUpMsgBody = e.message;
                this.showPopUpOk = true;
            } 
        },
        
        async doDetectSelectDayOfMonthChange(){

            // console.log("this.$refs.dayOfMonthSelected.value:: " + this.$refs.dayOfMonthSelected.value);
            
            try {
                await Parse.Cloud.run("managePayouts", {userObjectId: this.user.userObjectId, "payoutsFrequency" : "monthly", "payoutsFrequencyAnchor" : this.$refs.dayOfMonthSelected.value}); 
                this.userData.recipientPayoutsFrequency = "monthly";
                this.userData.recipientPayoutsFrequencyAnchor = this.$refs.dayOfMonthSelected.value;
            } catch (e) {
                this.popUpMsgTitle = "Sorry";
                this.popUpMsgBody = e.message;
                this.showPopUpOk = true;
            } 
        },

        
        setPaymentSelectors(){

            // console.log("doing set payment selectors");
            if (this.userData.recipientPayoutsFrequency !== undefined){

                if (this.userData.recipientPayoutsFrequency === "daily"){
                    this.$refs.switch1.checked = true;
                    this.$refs.switch2.checked = false;
                    this.$refs.switch3.checked = false;
                    this.$refs.switch4.checked = false;
                    this.showMakePayout = false;
                    this.showWeeklySelector = false;
                    this.showMonthlySelector = false;
                } else if (this.userData.recipientPayoutsFrequency === "weekly") {
                    this.$refs.switch1.checked = false;
                    this.$refs.switch2.checked = true;
                    this.$refs.switch3.checked = false;
                    this.$refs.switch4.checked = false;
                    this.showMakePayout = false;
                    this.showWeeklySelector = true;
                    this.showMonthlySelector = false;
                } else if (this.userData.recipientPayoutsFrequency === "monthly") {
                    this.$refs.switch1.checked = false;
                    this.$refs.switch2.checked = false;
                    this.$refs.switch3.checked = true;
                    this.$refs.switch4.checked = false;
                    this.showMakePayout = false;
                    this.showWeeklySelector = false;
                    this.showMonthlySelector = true;
                } else if (this.userData.recipientPayoutsFrequency === "manual") {
                    this.$refs.switch1.checked = false;
                    this.$refs.switch2.checked = false;
                    this.$refs.switch3.checked = false;
                    this.$refs.switch4.checked = true;
                    this.showMakePayout = true;
                    this.showWeeklySelector = false;
                    this.showMonthlySelector = false;
                }

                
                if (this.userData.recipientPayoutsFrequencyAnchor !== undefined){


                    if (this.userData.recipientPayoutsFrequency === "weekly"){
                        // console.log("this.userData.recipientPayoutsFrequency is " + this.userData.recipientPayoutsFrequency);
                         // console.log("this.userData.recipientPayoutsFrequencyAnchor is " + this.userData.recipientPayoutsFrequencyAnchor);
                        this.$refs.weekdaySelected.value = this.userData.recipientPayoutsFrequencyAnchor;
                    } else if (this.userData.recipientPayoutsFrequency === "monthly") {
                        // console.log("this.userData.recipientPayoutsFrequency is " + this.userData.recipientPayoutsFrequency);
                        // console.log("this.userData.recipientPayoutsFrequencyAnchor is " + this.userData.recipientPayoutsFrequencyAnchor);
                        this.$refs.dayOfMonthSelected.value = this.userData.recipientPayoutsFrequencyAnchor;
                    }

                } else {
                    // console.log("this.userData.recipientPayoutsFrequencyAnchor is UNDEFINED");
                }
                
            } else {
                    // console.log("DO DEFAULT DAILY");
                    this.$refs.switch1.checked = true;
                    this.$refs.switch2.checked = false;
                    this.$refs.switch3.checked = false;
                    this.$refs.switch4.checked = false;
                    this.showMakePayout = false;
                    this.showWeeklySelector = false;
                    this.showMonthlySelector = false;
            
            }

            // this.showMakePayout = true; // TODO override for DEV


        }
        
    },
    created(){

        // console.log("creating Payouts Manage");
        // console.log("this is USERDATA on Payouts Manage: " + JSON.stringify(this.userData, null, 2));
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        this.user.displayname = this.userData.displayname; //window.localStorage.getItem("tudis");
        this.user.email = this.userData.email;
        this.user.userObjectId = this.userData.userObjectId;
        // console.log("I'm created with user: " + this.user.userObjectId);
        this.userImageDataURL = this.userData.userThumbnail;

        this.readyToDisplay = true;

        this.assignModuleVars();

        
    },
    mounted(){
        // console.log("mounting Payouts Manage");
        this.setPaymentSelectors();
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Viewed recipient account screen " + new Date().toISOString(), this.globalPushForwardInterval);


    }
}
</script>

<style scoped>

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 8vh;
  height: 4vh;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 3vh;
  width: 3vh;
  left: 4px;
  bottom: 0.5vh;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #68D391;
}

input:focus + .slider {
  box-shadow: 0 0 1px #68D391;
}

input:checked + .slider:before {
  -webkit-transform: translateX(3.8vh);
  -ms-transform: translateX(3.8vh);
  transform: translateX(3.8vh);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
